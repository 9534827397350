import axios from "axios";

let API_URL = process.env.REACT_APP_API_URL;

const Advertise = {
    
    list: () => {
        return API_URL+'get-advertise';
    },

    detail: (data, successCb, failCb) => {
        return axios.post(API_URL+'edit-advertise', data, { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } }).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    update: (data, successCb, failCb) => {
        axios.post(API_URL+'update-advertise', data, { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } }).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    get_ads: (data, successCb, failCb) => {
        return axios.get(API_URL + 'get-ads/'+ data).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },
   
};

export default Advertise;