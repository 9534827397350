import React, { Component } from 'react';
import Auth from '../Services/Auth';
import helpers from '../Helpers/Helper';
import Setting from '../Services/Setting';
import Loader from '../Loader';
import GlobalContext from '../Context/GlobalContext';

const setPageTitle = (title) => {
  const prevTitle = document.title;
  document.title = title;
  return () => document.title = prevTitle;
}

class Login extends Component {
    static contextType = GlobalContext;
    
    constructor(props, context) {
        super(props);

        this.state = {
            email: "",
            password: "",
            error_message: null,
            errors: null,
            site_logo: '',
            site_name: '',
            admin_redirect: context.adminRedirect,
            isLoading: true
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
    }
    

    onChangeValue(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        Auth.login({ email: this.state.email, password: this.state.password }, (response) => {
            if (response.data.success == true) {
                if (response.data.user) {
                    localStorage.setItem("token", response.data.user.api_token);
                    localStorage.setItem("user_info", JSON.stringify(response.data.user));
                    window.location.pathname = this.state.admin_redirect;
                   
                } else {
                    helpers.toastAlert('error', "Not Authorized User.");
                }
            } else {
                 helpers.toastAlert('error', "Something went wrong.");
            }
        },
         (err) => {
                let errorObj = err.response.data.errors;
                Object.keys(errorObj).map((item) =>
                    errorObj[item].map((message) =>
                    helpers.toastAlert('error', message))
                )
            });
    }

    componentDidMount(){
        Setting.getSettings((res) => {
            if (res.data) {
                localStorage.setItem("site_data", JSON.stringify(res.data.data));
                this.setState({
                            isLoading: false, 
                            site_name: res.data.data.site_name,
                            site_logo: res.data.data.site_logo
                        })
            }
        }, (err) => {
            console.log(err)
        });
    }

    render() {        
        setPageTitle('Login');
        if (localStorage.getItem('user_info') && localStorage.getItem('token') && localStorage.getItem('site_data')) {
            window.location.pathname = this.state.admin_redirect;
        } else{
            return (
                this.state.isLoading ? <Loader/>
                : 
                <>
                <section className="login-section">
                    <div className="container my-auto">
                        <div className="row g-0 justify-content-center">
                            <div className="col-sm-12">
                                <form onSubmit={this.handleSubmit} className="logo-form">
                                    <div className="login-logo">
                                        <img src={this.state.site_logo} alt={this.state.site_name}/>
                                    </div>
                                    <hr/>
                                    <h2 className="login-heading mb-3">Login</h2>
                                    <div className="mb-3">
                                    <input type="email" name="email" id="username" className="form-control" onChange={this.onChangeValue} placeholder="Enter Username" required/>
                                    </div>
                                    <div className="mb-3">
                                    <input type="password" name="password" id="password" className="form-control" onChange={this.onChangeValue} placeholder="Enter Password" required/>
                                    </div>
                                    <div className="w-100">
                                        <button type="submit" className="btn btn-dark w-100">Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                </>
            );
        }
    }
}

export default Login;
