import React, { Component } from 'react';
import Header from '../frontend/components/Header';
import {Route, Switch, BrowserRouter} from "react-router-dom";

class NotFound extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
            path : props.path
        }
    }

    render() {
        return (
            <>
            <div className="container-fluid container-common">
                <div className="row justify-content-center">
                <section className="d-flex align-items-center align-middle p-3 w-100 start-0 top-0 h-100">
                    <style jsx="true">{`
                        h1{
                            font-size: calc( 12vw + 24px);
                        }
                        h5{
                            font-size: calc( 1vw + 16px);
                        }
                    `}</style>
                    <div className="col-sm-12 text-center pb-4">
                        <h1 className="fw-bolder text-danger m-0">404</h1>
                        <h5 className="fw-bolder m-0">Opps! Page Not Found</h5>
                        <p>Sorry, the page you're looking for doesn't exist.</p></div>
                </section>
                </div>
            </div>
        </>
        )
    }
}

export default NotFound;