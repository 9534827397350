import React, { Component } from 'react';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

class Datatable extends Component {
    componentDidMount() {
        this.loadDataTable()
    }

    loadDataTable(){
        let dt_data = this.props.dt_data;
        if ($.fn.DataTable.isDataTable('#caption_datatable')) {
            $('#caption_datatable').DataTable().clear().ajax.reload(null, false);
        }else{
            $('#caption_datatable').DataTable({
                "processing": true,
                "serverSide": true,
                "paging": dt_data.dt_paging,
                "ajax": {
                    "url": dt_data.dt_url,
                    "headers": {
                        "Authorization": 'Bearer ' + localStorage.getItem("token")
                    },
                },
                columns: dt_data.dt_column,
                columnDefs: dt_data.dt_column_defs, 
                order: [[ 0, 'desc' ]],
            });
        }
    }
    
    render(){
        return (
            <>
            <table id="caption_datatable" className="border table table-striped table-hover">
                <thead className="table-dark">
                    <tr>
                    </tr>
                </thead>
            </table>               
            </>
        )
    }
}

export default Datatable;
