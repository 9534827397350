import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

class Pagination extends React.Component {

    constructor(props)
    {
        super(props);

        this.changePage = this.changePage.bind(this);
    }

    changePage(e){
        this.props.onPageChange(e);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    render()
    {
        return this.props.data && this.props.data.total > this.props.data.per_page?(
            <>
                <nav aria-label="Page navigation example">
                    <ReactPaginate
                        pageCount={this.props.data.last_page}
                        initialPage={this.props.data.current_page - 1}
                        forcePage={this.props.data.current_page - 1}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={2}
                        previousLabel="&#x276E;"
                        nextLabel="&#x276F;"
                        containerClassName="pagination pagination-sm justify-content-end"
                        activeClassName="page-item active"
                        disabledClassName="page-item disabled"
                        breakLinkClassName="page-link text-center"
                        pageLinkClassName="page-link text-center"
                        previousLinkClassName="page-link text-center"
                        nextLinkClassName="page-link text-center"
                        onPageChange={this.changePage}
                        disableInitialCallback={true}
                    />
                </nav>
            </>
        ):null
    }
};

export default withRouter(Pagination);