import React, { Component } from 'react';
import Page from '../../Services/Page';
import helpers from '../../Helpers/Helper';
import Loader from '../../Loader';


const setPageTitle = (title) => {
    const prevTitle = document.title;
    document.title = title;
    return () => document.title = prevTitle;
}

class PageEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page:'',
            isLoading: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ isLoading: true });

        const formData = new FormData(e.target);
        formData.append("id", this.state.page.id);

        Page.update(formData, (response) => {
            if (response.status == 201) {
                helpers.toastAlert('success', response.data.message);
                this.props.history.goBack();
            } else {
                helpers.toastAlert('error', response.data.message);
            }
            this.setState({ isLoading: false });
        }, (err) => {
            this.setState({ isLoading: false });
            let errorObj = err.response.data.errors;
            Object.keys(errorObj).map((item) => helpers.toastAlert('error', errorObj[item]))
        })
    }
    async componentDidMount(){
        this.getDetails();
    }

    getDetails(){
        const { match: { params } } = this.props;

        Page.detail( { id: params.id } , (res) => {
            if (res.data) {
                this.setState({
                    page: res.data.data,
                    isLoading: false
                })
            }
        }, (err) => {
            console.log(err)
        });
    }

    render(){
        setPageTitle('Pages');
        return (
            this.state.isLoading ? <Loader/>
            : 
            <>
            <div className="main-wrapper">
                <div className="container-fluid container-common">
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <h2 className="page-heading">
                            Pages
                            </h2>
                        </div>
                        <div className="mt-2">
                            <div className="d-flex justify-content-end"><button onClick={this.props.history.goBack} className="btn btn-dark px-3 btn-sm"><svg className="icon me-2"><use href="#icon_arrowleft"></use></svg> Back</button></div>
                        </div>

                        <div className="col-sm-8 mt-4">
                            <div className="add-listing bg-white p-5">
                                <form action="#" onSubmit={this.handleSubmit} method="post">
                                    <div className="row g-5 mb-5">
                                        <label htmlFor="title" className="col-sm-2 col-form-label">Title</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" name="title" id="title" defaultValue={this.state.page.title}/>
                                        </div>
                                        <label htmlFor="description" className="col-sm-2 col-form-label">Description</label>
                                        <div className="col-sm-10">
                                            <textarea rows="10" placeholder="Write here..." className="form-control" name="description" id="description" defaultValue={this.state.page.description}></textarea>
                                        </div>
                                        <label htmlFor="content" className="col-sm-2 col-form-label">Content</label>
                                        <div className="col-sm-10">
                                            <textarea className="form-control" name="content" id="content" defaultValue={this.state.page.content}></textarea>
                                        </div>
                                    </div>
                                        
                                    <div className="row">
                                        <div className="col">
                                            <button type="submit" className="btn btn-danger btn-lg px-3 me-2">
                                            <svg className="icon me-2"><use href="#icon_submit"></use></svg> Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                        <div className="col-sm-12 mb-5">
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default PageEdit;
