import axios from "axios";

let API_URL = process.env.REACT_APP_API_URL;

const Stock = {
    list: (page = 1) => {
        return axios.get(API_URL + 'get-stock-image?page=' + page, { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } });
    },

    add: (data, successCb, failCb) => {
        axios.post(API_URL + 'add-stock-image', data).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    delete: (data, successCb, failCb) => {
        axios.post(API_URL + 'delete-stock-image', data).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    getRandomImage: (_data) => {
        var data = ( _data != '' )? '/'+_data : '';

        return axios.get(API_URL + 'get-stock-photo' + data);
    },
};

export default Stock;