import React, { Component } from 'react';
import { Link } from "react-router-dom";
import GlobalContext from '../Context/GlobalContext';
import StockService from '../Services/Stock';
import CaptionService from '../Services/Caption';
import Header from './components/Header';
import $ from 'jquery';
import {Helmet} from "react-helmet";

var v = document.getElementById('commonEditor');
if(v) v.remove();

class Edit extends Component {
    static contextType = GlobalContext;
    constructor(props, context) {
        super(props);

        this.state = {
            site_logo: context.settingDetails.site_logo,
            site_watermark: context.settingDetails.site_watermark,
            site_name: context.settingDetails.site_name,
            uuid: "", 
            cancel: false, 
            changephoto:true,
        }
        this.changePhoto = this.changePhoto.bind(this);
        this.saveCaption = this.saveCaption.bind(this);
    }

    do_load_script(){
        localStorage.setItem('tis', Date.now());
        var script_editor = document.createElement('script');
        script_editor.src = 'Editor.js';
        script_editor.id = 'commonEditor';
        document.body.appendChild(script_editor);
    }

    async componentDidMount() {
        this.do_load_script();
        var deviceImage = localStorage.getItem("device_image");
        if (deviceImage){
            this.setState({ cancel: true, changephoto: false})
            this.getRandomPhoto(deviceImage);
        }else{
            if (localStorage.getItem("random_image") && 
                this.props.match.params.uuid == localStorage.getItem("uuid")){
                this.setState({ stockImage: localStorage.getItem("random_image")})
            }else{
                this.getRandomPhoto();
            }
        }
    }

    getRandomPhoto(data = ''){
        $('body').addClass('body-bubbleerror');
        $('.bubbles-errorbox h2').html('Loading ...')
        var validSlug = (this.props.match.params.uuid.match(new RegExp("-", "g")) || []).length
        if (validSlug == 4 && this.props.match.params.uuid.length == 36 ){
            StockService.getRandomImage(data).then(res => {
                this.setState({ stockImage: res.data});
                if(data == ''){
                    localStorage.setItem("random_image", res.data);
                }
                localStorage.setItem("uuid", this.props.match.params.uuid);
                $('body').removeClass('body-bubbleerror');
            }).catch(err => {
                console.log(err);
            });
        }else{
            this.setState({cancel:true, changephoto:false})
            $('body').removeClass('body-bubbleerror');
        }
    }

    changePhoto(){
        this.getRandomPhoto();
    }

    dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}

    saveCaption(e){
        e.preventDefault();
        $('body').addClass('body-bubbleerror');
        $('.bubbles-errorbox h2').html('Saving Caption ...')
        document.querySelector('#previewModal [data-bs-dismiss="modal"]').click();

        var blob = this.dataURItoBlob(document.getElementById('imagebase64').value);
 
        const formData = new FormData(e.target);
        formData.append('caption_image', blob)
        CaptionService.add(formData, (res) => {
            $('body').removeClass('body-bubbleerror');
            if (res.status == 201){
                this.props.history.push({
                    pathname: "/show/" + res.data.temp_url,
                });
            }
        }, (err) => {
            console.log(err);
            document.querySelector('.modal-backdrop').remove();
        })
    }

    render() {
        return (
                <>
                    <Header stock_image={this.state.stockImage} changePhoto={this.state.changephoto} cancel={this.state.cancel} changePhotoFunction={this.changePhoto}/>
                    <Helmet>
                        <title>Edit Your Caption</title>
                        <meta name="description" content={this.state.site_name} />
                    </Helmet>
                    <div className="bubbles-errorbox">
                        <h2>Loading...</h2>
                    </div>
                    <div className="caption-editor" id="imagePane">
                        <div className="image-bigpreview" data-thisremove="8991919">
                            {
                                (this.state.stockImage) ?
                                    <img className="selected-picture" src={this.state.stockImage} alt="pane-bigimage" />
                                    : ''
                            }
                        </div>
                        <div className="caption-watermark">
                            <img src={this.state.site_watermark} alt="logo-watermark" />
                        </div>
                    </div>
                    
                    <div className="d-none">
                    <img src="./images/shapes/round-1.png" alt="round" />
                    <img src="./images/shapes/round-2.png" alt="round" />
                    <img src="./images/shapes/round-3.png" alt="round" />
                    <img src="./images/shapes/round-4.png" alt="round" />
                    <img src="./images/shapes/round-5.png" alt="round" />
                    <img src="./images/shapes/round-6.png" alt="round" />
                    <img src="./images/shapes/round-7.png" alt="round" />
                    <img src="./images/shapes/round-8.png" alt="round" />

                    <img src="./images/shapes/medium-1.png" alt="medium" />
                    <img src="./images/shapes/medium-2.png" alt="medium" />
                    <img src="./images/shapes/medium-3.png" alt="medium" />
                    <img src="./images/shapes/medium-4.png" alt="medium" />
                    <img src="./images/shapes/medium-5.png" alt="medium" />
                    <img src="./images/shapes/medium-6.png" alt="medium" />

                    <img src="./images/shapes/square-1.png" alt="square" />
                    <img src="./images/shapes/square-2.png" alt="square" />
                    <img src="./images/shapes/square-3.png" alt="square" />
                    <img src="./images/shapes/square-4.png" alt="square" />
                    <img src="./images/shapes/square-5.png" alt="square" />
                    <img src="./images/shapes/square-6.png" alt="square" />

                    <img src="./images/shapes/angry-1.png" alt="angry" />
                    <img src="./images/shapes/angry-2.png" alt="angry" />
                    <img src="./images/shapes/angry-3.png" alt="angry" />
                    <img src="./images/shapes/angry-4.png" alt="angry" />
                    <img src="./images/shapes/angry-5.png" alt="angry" />
                    <img src="./images/shapes/angry-6.png" alt="angry" />

                    <img src="./images/shapes/thinking-1.png" alt="thinking" />
                    <img src="./images/shapes/thinking-2.png" alt="thinking" />
                    <img src="./images/shapes/thinking-3.png" alt="thinking" />
                    <img src="./images/shapes/thinking-4.png" alt="thinking" />
                    <img src="./images/shapes/thinking-5.png" alt="thinking" />
                    <img src="./images/shapes/thinking-6.png" alt="thinking" />
                </div>
                    {/* Start | Preview & Save Modal */}
                    <div className="modal fade1" id="previewModal" tabIndex="-1">
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content rounded-0">
                                <div className="modal-header bg-light">
                                    <h5 className="modal-title">Save your image</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div id="imagebase64" className="d-none" ></div>
                                <form onSubmit={this.saveCaption}>
                                    <div className="modal-body">
                                        <div className="d-block image-preview mb-3">
                                            <img id="canvas4preview" className="d-block w-100" alt="img for preview" />
                                        </div>
                                        <p>
                                            By proceeding you agree to our Terms Of Service. You confirm that no copyrights are violated and that this photo is not of defamatory or libelous nature.
                                        </p>
                                        {/* <p>
                                            <strong className="d-block">Is this something private?</strong>
                                            We can prevent search engines from indexing this image and hide social media icons for sharing.
                                        </p>
                                        <select className="form-select form-select-sm">
                                            <option value="0">It's funny and can be published</option>
                                            <option value="1">Keep it private please</option>
                                        </select>
                                        <p className="mt-3 mb-1"><strong className="d-block">Want to receive a download link by email?</strong></p>
                                        <input type="email" className="form-control form-control-sm" placeholder="your@email.com" /> */}
                                        <textarea id="caption-title" className="d-none" name="caption_title"></textarea>
                                        <textarea id="caption-text" className="d-none" name="caption_text"></textarea>
                                        <textarea id="caption-type" className="d-none" name="caption_type"></textarea>
                                    </div>
                                    <div className="modal-footer bg-light">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                        <button type="submit" className="btn btn-danger">Yes, Save It !</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* End | Preview & Save Modal */}

                </>
        )
    }
}

export default Edit;
