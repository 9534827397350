import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

class AdsCard extends Component{
    constructor(props){
        super(props);
    } 

    render(){       
        return(
            <div className="col">
                <div className="card card-box shadow-sm bg-body rounded overflow-hidden border-0 position-relative cursor-pointer">
                    <div className={'ads-code ' + this.props.size}>
                        <div className="ads-title text-center">
                            Advertisement
                        </div>
                        {
                            this.props.ads_data ?
                            <div className="ads-content" dangerouslySetInnerHTML={{
                                __html: this.props.ads_data.script
                            }}></div>
                            
                            :
                            ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(AdsCard);