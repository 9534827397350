import React, { Component } from 'react';
import _ from 'lodash';
import Setting from '../../Services/Setting';
import helpers from '../../Helpers/Helper';
import Header from '../../backend/components/Header';
import GlobalContext from '../../Context/GlobalContext';
import Loader from '../../Loader';

class Settings extends Component {
    static contextType = GlobalContext;

    constructor(props, context) {
        super(props);
        // create a ref to store the DOM element
        this.state = {
            site_logo: context.settingDetails.site_logo,
            site_name: context.settingDetails.site_name,
            dummy_logo: context.settingDetails.dummy_logo,
            settings: '',
            site_watermark: context.settingDetails.site_watermark,
            isLoading: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChanged = this.handleInputChanged.bind(this);
 
        this.uploadAttachments = this.uploadAttachments.bind(this);
    }

  

    handleInputChanged(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        const formData = new FormData(e.target);

        Setting.add(formData, (response) => {
            console.log(response)
            if (response.status == 201) {
                if(response.data.file_error){
                    helpers.toastAlert('error', response.data.message);
                }else{
                    helpers.toastAlert('success', response.data.message);
                }
                localStorage.setItem("site_data", JSON.stringify(response.data.data.original.data));

                this.setState({
                    site_logo: response.data.data.original.data.site_logo,
                    site_name: response.data.data.original.data.site_name,
                    site_watermark: response.data.data.original.data.site_watermark,
                })
            } else {
                helpers.toastAlert('error', response.data.message)
            }
        }, (err) => {
            console.log(err)
            let errorObj = err.response.data.errors;
            Object.keys(errorObj).map((item) => helpers.toastAlert('error', errorObj[item])
            )
        })
    }
   
    async componentDidMount() {
        this.getDetils();
    }

    getDetils(){
        Setting.get((res) => {
            if (res.data) {
                this.setState({
                    settings: res.data,
                    isLoading: false
                })
            }
        }, (err) => {
            console.log(err)
        });
    }

    uploadAttachments(e) {
        // const fileExt = ['jpeg', 'jpg', 'png'];
        // const files = e.target.files[0];
        // let ext = files.name.split('.').pop();
        // if (!fileExt.includes(ext.toLowerCase())) {
        //     helpers.toastAlert('error', 'Please upload file in jpeg, jpg, png format.');
        //     e.target.value = null;
        // } else {
            this.setState({
                [e.target.name]: e.target.value
            });
        //}
    }

    render() {
        return (
            this.state.isLoading ? <Loader/>
            : 
            <>
            <Header siteLogo={this.state.site_logo}/>
            <div className="main-wrapper">
                <div className="container-fluid container-common">
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <h2 className="page-heading">
                            Settings
                            </h2>
                        </div>
                        
                        <div className="col-sm-8 mt-4">
                            <div className="add-listing bg-white p-5">
                                <form onSubmit={this.handleSubmit} method="post">
                                    
                                    {
                                        (this.state.settings) ?
                                        this.state.settings.data.map((setting) =>
                                            <div className="row g-5 mb-5" key={setting.id}>
                                                <label htmlFor={'setting_'+setting.id} className="col-sm-3 col-form-label">{_.startCase(setting.option_name)}</label>
                                                
                                                {(setting.option_name == 'site_logo' || setting.option_name == 'site_watermark') ?
                                                <>
                                                <div className="col-sm-6">
                                                    <input type="file" className="form-control" onChange={this.uploadAttachments} name={'option_value['+setting.id+']'} id={'setting_'+setting.id}/>
                                                </div>
                                                {(setting.option_name == 'site_logo')?
                                                <div className="col-sm-3 logo-caption">
                                                    <img src={this.state.site_logo+'?'+Date.now()} alt={this.state.site_name} onError={(e)=>{e.target.onerror = null; e.target.src=this.state.dummy_logo}}/>
                                                </div>:
                                                    <div className="col-sm-3 logo-caption">
                                                        <img src={this.state.site_watermark+'?'+Date.now()} alt={this.state.site_name} onError={(e)=>{e.target.onerror = null; e.target.src=this.state.dummy_logo}}/>
                                                    </div>
                                                }
                                                </>
                                                :
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control" onChange={this.handleInputChanged} name={'option_value['+setting.id+']'} id={'setting_'+setting.id} defaultValue={setting.option_value}/>
                                                </div>
                                                }
                                            </div>
                                        ) : 
                                            <div className="row g-5 mb-5">
                                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Settings Not Available.</label>
                                                <div className="col-sm-10">
                                                <input type="password" className="form-control" id="inputPassword"/>
                                                </div>
                                            </div>
                                    }

                                    {
                                    (this.state.settings) ?
                                    <div className="row">
                                        <div className="col">
                                            <button type="submit" className="btn btn-danger btn-lg px-3 me-2">
                                                <svg className="icon me-2"><use href="#icon_submit"></use></svg> Update</button>
                                        </div>
                                    </div>
                                    : '' }
                                </form>
                            </div>
                        </div>
                        
                        <div className="col-sm-12 mb-5">
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default Settings;