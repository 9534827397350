import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import Users from '../../Services/Users';
import Datatable from '../components/Datatable';
import helpers from '../../Helpers/Helper';
import Loader from '../../Loader';

const setPageTitle = (title) => {
    const prevTitle = document.title;
    document.title = title;
    return () => document.title = prevTitle;
}

const UserType = (path) => {
    if(path == '/super-admins'){ 
        return 1;
    }else if(path == '/admins'){
        return 2;
    }else{
        return 3;
    }
}

class UserList extends Component {
    constructor(props) {
        super(props);

        let user_type = UserType(this.props.location.pathname);

        this.state = {
            user_id: localStorage.getItem('user_info') ? JSON.parse(localStorage.getItem('user_info')).id : 0,
            user_type: user_type,
            heading: user_type == 1 ? 'Super Admin' : (user_type == 2 ? 'Admin' : 'User'),
            dt_data: {
                dt_url: Users.list(user_type),
                dt_column: [
                    { "data": "id", "title": "ID" },
                    { "data": "name", "title": "Name" },
                    { "data": "email", "title": "Email" },
                    { "data":"id", "title":"Action" },
                ],
                dt_column_defs: [{
                    targets: 3,
                    createdCell: (td, cellData, rowData, row, col) =>
                        
                        ReactDOM.render(
                            <>
                            { rowData.id != this.state.user_id ? <button type="button" className="btn btn-danger btn-sm" onClick={() => this.handleDelete(rowData.id)}><svg className="icon"><use href="#icon_delete"></use></svg></button> : ''}    
                            </>, td),
                }],
                dt_paging: true
            },
            isLoading: true
        }

        this.handleDelete = this.handleDelete.bind(this);
        this.datatable_reload = React.createRef();
    }

    handleDelete(id){
        var r = window.confirm("Delete this "+ this.state.heading +"?");
        if (r == true) {
            Users.delete({ id: id }, (response) => {
                if (response.status == 200) {
                    helpers.toastAlert('success', response.data.message);
                    this.datatable_reload.current.loadDataTable();
                } else {
                    helpers.toastAlert('error', response.data.message);
                }
            }, (err) => {
                console.log(err)
            })
        }
    }

    async componentDidMount() {
        this.setState({isLoading: false});
    }

    render(){
        setPageTitle('Users');
        return (
            this.state.isLoading ? <Loader/>
            : 
            <>
            <div className="main-wrapper">
                <div className="container-fluid container-common">
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <h2 className="page-heading">
                            { this.state.heading }s
                            </h2>
                        </div>
                        { this.state.user_type > 2 ? '' :
                        <div className="col-sm-12 col-12 d-flex mt-2">
                            <div className="justify-content-end d-flex w-100">
                                <div><Link to={'/add-user'} className="btn btn-danger px-3"><svg className="icon me-2"><use href="#icon_pluscircle"></use></svg> Add User</Link></div>
                            </div>
                        </div>
                        }
                        <div className="col-sm-12 mt-4">
                            <div className="userlist bg-white p-5">
                                {
                                    (this.state.dt_data) ?
                                        <Datatable dt_data={(this.state.dt_data) ? this.state.dt_data : null} ref={this.datatable_reload}></Datatable>
                                        : null
                                }
                            </div>
                        </div>
                        
                        <div className="col-sm-12 mb-5">
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default UserList;