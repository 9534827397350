import axios from "axios";

let API_URL = process.env.REACT_APP_API_URL;

const Auth = {
    login: (data, successCb, failCb) => {
        axios.post(API_URL+'sign-in', data).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },
    logout: (successCb, failCb) => {
        axios.get(API_URL+'logout', { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } })
            .then(response => {
                localStorage.clear();
                successCb(response);
            }).catch(err => {
                failCb(err);
            });
    },

};

export default Auth;