import React from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import Header from '../components/Header';

const AuthenticatedRoute = ({ component: Component, roleId, ...rest }) => ( <
    Route {...rest }
    render = {
        props => localStorage.getItem("token") && localStorage.getItem("site_data") && roleId == 1 ? ( 
            <>
            <Header />
            <Component {...props }/>
            </>
        ) : (
            window.location.pathname = '/'
        )
    }
    />
);

export default withRouter(AuthenticatedRoute);