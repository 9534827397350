import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Page from '../../Services/Page';
import Datatable from '../components/Datatable';
import Loader from '../../Loader';


const setPageTitle = (title) => {
    const prevTitle = document.title;
    document.title = title;
    return () => document.title = prevTitle;
}

class PageList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dt_data: {
                dt_url: Page.list(),
                dt_column: [
                    { "data": "id", "title": "ID" },
                    { "data": "title", "title": "Title" },
                    { "data": "description", "title": "Description" },
                    // { "data": "status", "title": "Status" },
                    { "data": "id", "title": "Action" },
                ],
                dt_column_defs: [
                    {
                        targets: 0,
                        "visible": false,
                    },
                    {
                    targets: 3,
                    createdCell: (td, cellData, rowData, row, col) =>
                        ReactDOM.render(<>
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => this.handleEdit(rowData.id)}><svg className="icon"><use href="#icon_edit"></use></svg></button> 
                        </>, td),
                    }
                ],
                dt_paging: false
            },
            isLoading: true
        }

        this.handleEdit = this.handleEdit.bind(this);
    }

    componentDidMount() {
        this.setState({ isLoading: false})
    }

    handleEdit(id){
        this.props.history.push({ 
            pathname: '/pages/edit/'+id,
        });
    }

    render(){
        setPageTitle('Pages');
        return (
            this.state.isLoading ? <Loader/>
            : 
            <>
            <div className="main-wrapper">
                <div className="container-fluid container-common">
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <h2 className="page-heading">
                            Pages
                            </h2>
                        </div>

                        {/* <div className="col-sm-12 col-12 d-flex mt-2">
                            <div className="justify-content-end d-flex w-100">
                                <div><Link to={'/pages/add'} className="btn btn-danger px-3"><svg className="icon me-2"><use href="#icon_pluscircle"></use></svg> Add Page</Link></div>
                            </div>
                        </div> */}
                        
                        <div className="col-sm-12 mt-4">
                            <div className="userlist bg-white p-5">
                                {
                                    (this.state.dt_data)?
                                        <Datatable dt_data={(this.state.dt_data) ? this.state.dt_data : null}></Datatable>
                                    :null
                                }
                                    
                            </div>
                        </div>
                        
                        <div className="col-sm-12 mb-5">
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default PageList;
