import React from 'react';

const GlobalContext = React.createContext()

let user = localStorage.getItem("user_info")
  ? JSON.parse(localStorage.getItem("user_info"))
  : "";

let setting = localStorage.getItem("site_data")
  ? JSON.parse(localStorage.getItem("site_data"))
  : "";

let token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : "";
 
export const initialState = {
  userDetails: "" || user,
  settingDetails: "" || setting,
  token: "" || token,
  adminRedirect: '/admin/dashboard',
  loginRedirect: '/login',
};

export const GlobalProvider = ({ children }) => {
    return (
      <GlobalContext.Provider value={initialState}>
          {children}
      </GlobalContext.Provider>
    );
};

export default GlobalContext;