import React, { Component } from 'react';

class StockBubbleCard extends Component{
    constructor(props){
        super(props);

        this.deleteStockImage = this.deleteStockImage.bind(this);
    }

    deleteStockImage(e){
        this.props.deleteStock({
            filename: this.props.filename
        });
    }

    render(){
        return(
            <div className="col">
                <div className="card card-box shadow-sm bg-body rounded overflow-hidden border-0 position-relative">
                    <img src={this.props.fileurl} alt={this.props.filename} className="w-100"/>
                    {this.props.filename != null ?
                        <div className="card-body">
                            <p className="card-text">{this.props.filename}</p>
                            <div className="d-flex justify-content-evenly">
                                <button type="button" className="card-link btn btn-danger btn-sm" onClick={this.deleteStockImage}><svg className="icon me-1"><use href="#icon_delete"></use></svg>Delete</button>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        )
    }
}
export default StockBubbleCard;