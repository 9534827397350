import React, { Component } from 'react';
import PageService from '../Services/Page';
import Loader from '../Loader';
import {Helmet} from "react-helmet";

class Page extends Component {
    constructor(props) {
        super(props);
        // create a ref to store the DOM element
        this.state = {
            page_data: '',
            isLoading: true
        }
    }

    componentDidMount() {
        var slug = this.props.match.params.slug
        this.listData(slug);
    }

    componentWillReceiveProps(newProps) {
        var slug = newProps.match.params.slug
        this.listData(slug);
    }

    listData(pagename) {
        PageService.pageData({ slug: pagename }, (res) => {
            this.setState({ page_data: res.data.data,  isLoading: false})
        }, (err) => {
            console.log(err);
        })
    }

    render() {
        return (
            <>
            <Helmet>
                <title>{this.state.page_data.title ? this.state.page_data.title : ''}</title>
                <meta name="description" content={this.state.site_name} />
            </Helmet>
            {
            this.state.isLoading ? <Loader/>
            : 
            <>
            <div className="container-fluid">
                <h1>{this.state.page_data.title}</h1>
                <h3>{this.state.page_data.description}</h3>
                <h6>{this.state.page_data.content}</h6>
            </div>
            </>
            }
            </>   
        )
    }
}

export default Page;