import axios from "axios";

let API_URL = process.env.REACT_APP_API_URL;

const Users = {

    list: (userType) => {
        if (userType == 1) {
            return API_URL + 'get-super-admin-list';
        } else if (userType == 2) {
            return API_URL + 'get-admin-list';
        } else {
            return API_URL + 'get-user-list';
        }

    },
    add: (data, successCb, failCb) => {
        axios.post(API_URL + 'store-user', data, { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } }).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    getUserRole: async(successCb, failCb) => {
        try {
            const response = await axios.get(API_URL + 'get-user-roles', { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } });
            successCb(response);
        } catch (err) {
            failCb(err);
        }
    },

    delete: (data, successCb, failCb) => {
        axios.post(API_URL + 'delete-user', data, { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } }).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },    
};

export default Users;