import axios from "axios";

let API_URL = process.env.REACT_APP_API_URL;

const Caption = {

    add: (data, successCb, failCb) => {
        axios.post(API_URL + 'store-caption', data).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    list: (page = 1, sortBy = 'newest') => {
        return axios.get(API_URL + 'get-caption-image?page=' + page + '&sort=' + sortBy, { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } })
    },

    publicList: (page = 1, sortBy = 'newest', searchBy = null) => {
        if (searchBy) {
            return axios.get(API_URL + 'get-caption-public-image?page=' + page + '&sort=' + sortBy + '&s=' + searchBy)
        } else {
            return axios.get(API_URL + 'get-caption-public-image?page=' + page + '&sort=' + sortBy)
        }
    },

    detail: (temp_url, successCb, failCb) => {
        return axios.get(API_URL + 'caption-details/' + temp_url).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    status: (data, successCb, failCb) => {
        return axios.post(API_URL + 'change-status', data, { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } }).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    delete: (data, successCb, failCb) => {
        axios.post(API_URL + 'delete-caption-image', data, { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } }).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    like_dislike: (data, successCb, failCb) => {
        return axios.post(API_URL + 'caption-like', data).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    share: (data, successCb, failCb) => {
        return axios.post(API_URL + 'caption-share', data).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    add_comment: (data, successCb, failCb) => {
        return axios.post(API_URL + 'add-comment', data).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    add_report: (data, successCb, failCb) => {
        return axios.post(API_URL + 'add-report', data).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    add_shareByMail: (data, successCb, failCb) => {
        return axios.post(API_URL + 'add-share-by-mail', data).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    caption_meta_web_url: (temp_url) => {
        return API_URL + 'caption-details-for-crawler/' + temp_url;
    }
};

export default Caption;