import React, { Component } from 'react';
import Setting from '../../Services/Setting';
import helpers from '../../Helpers/Helper';
import Loader from '../../Loader';

const setPageTitle = (title) => {
    const prevTitle = document.title;
    document.title = title;
    return () => document.title = prevTitle;
}

class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state={
            isLoading: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
    }

    onChangeValue(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ isLoading: true })

        const formData = new FormData(e.target);

        Setting.changePassword(formData, (res) => {
            if(res.data.success){
                helpers.toastAlert('success', res.data.message);
            }else{
                helpers.toastAlert('error', res.data.message);
            }
            this.setState({ isLoading: false })
        }, (err) => {
            this.setState({ isLoading: false })
            let errorObj = err.response.data.errors;
            Object.keys(errorObj).map((item) =>
                errorObj[item].map((message) =>
                    helpers.toastAlert('error', message))
            )
        })
    }

    render() {
        setPageTitle('Change Password');
        return (
            this.state.isLoading ? <Loader/>
            : 
            <>
                <div className="main-wrapper">
                    <div className="container-fluid container-common">
                        <div className="row justify-content-center">
                            <div className="col-sm-12">
                                <h2 className="page-heading">
                                    Change Password
                            </h2>
                            </div>

                            <div className="col-sm-8 mt-4">
                                <div className="add-listing bg-white p-5">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row g-5 mb-5">
                                            <label htmlFor="name" className="col-sm-2 col-form-label">Old Password</label>
                                            <div className="col-sm-10">
                                                <input type="password" className="form-control" name="old_password" placeholder="Old Password" id="name" onChange={this.onChangeValue} required/>
                                            </div>
                                            <label htmlFor="password" className="col-sm-2 col-form-label">Password</label>
                                            <div className="col-sm-10">
                                                <input type="password" className="form-control" name="password" placeholder="Password" id="password" onChange={this.onChangeValue} required/>
                                            </div>
                                            <label htmlFor="password_confirmation" className="col-sm-2 col-form-label">Retype Password</label>
                                            <div className="col-sm-10">
                                                <input type="password" className="form-control" name="password_confirmation" placeholder="Retype Password" id="password_confirmation" onChange={this.onChangeValue}required/>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <button type="submit" className="btn btn-danger btn-lg px-3 me-2">
                                                    <svg className="icon me-2"><use href="#icon_submit"></use></svg> Change Password
                                            </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="col-sm-12 mb-5">
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ChangePassword;
