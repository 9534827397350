import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Stock from '../../Services/Stock';
import StockBubbleCard from '../components/StockBubbleCard';
import Masonry from 'masonry-layout/dist/masonry.pkgd';
import helpers from '../../Helpers/Helper';
import Pagination from '../../Helpers/Pagination';
import Loader from '../../Loader';
import imagesLoaded from 'imagesloaded/imagesloaded.pkgd';

const MasonryInt = () => {
    var grid = document.querySelector('#row_masonry');
    var imgLoad = imagesLoaded(grid);

    function onAlways( instance ) {
        var msnry = new Masonry(grid, {
            percentPosition: true,
            itemSelector: '.col',
        });
        msnry.layout()

        document.querySelector('.body-bubbleerror').style.display = "none";
    }
    imgLoad.on( 'always', onAlways );  
}

const setPageTitle = (title) => {
    const prevTitle = document.title;
    document.title = title;
    return () => document.title = prevTitle;
}

class StockList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stock_data: '',
            pageCount: 1,
			currentPage: 1,
            isLoading: true
        }

        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleDelete    = this.handleDelete.bind(this);
    }

    componentDidUpdate(){
        document.querySelector('.body-bubbleerror').style.display = "block";
        if(this.state.isLoading == false){
            MasonryInt()
        }
    }
    componentDidMount(){
        this.listData()
    }

    async handlePageClick(data) {
        this.setState({ isLoading: true });
        const page = data.selected >= 0 ? data.selected + 1 : 0;
		await Promise.resolve(this.setState(() => ({ currentPage: page })));

		this.listData()
	}

    async listData(page = this.state.currentPage) {
        Stock.list(page).then(response => {
            this.setState({ stock_data: response.data, isLoading: false });
        }).then()
        .catch(err => {
            console.log(err);
        });
    }

    handleDelete(filename){
        var r = window.confirm("Delete this stock image?");
        if (r == true) {
            this.setState({ isLoading: true });
            Stock.delete(filename, (response) => {
                if (response.status == 200) {
                    helpers.toastAlert('success', response.data.message);
                    this.listData()
                } else {
                    helpers.toastAlert('error', response.data.message);
                }
            }, (err) => {
                console.log(err);
            })
        }
    }

    render(){
        setPageTitle('Gallery');
        return ( 
            <>
            <Loader/>

            <div className="main-wrapper">
                <div className="container-fluid container-common">
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <h2 className="page-heading">
                                Stock Images
                            </h2>
                        </div>
                        
                        <div className="col-sm-12 mt-2">
                            <div className="row">
                                <div className="col-sm-12 col-12 border1 d-flex">
                                    <div className="justify-content-between d-flex w-100">
                                        <div></div>
                                        <div><Link to="/upload-stock-images" className="btn btn-danger px-3"><svg className="icon me-2"><use href="#icon_upload"></use></svg> Upload Stock Images</Link></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6 gy-4 mt-0" data-masonry='{"percentPosition": true }' id="row_masonry">
                                {
                                    this.state.stock_data.data ? (
                                        this.state.stock_data.data.map((item)=>
                                            <StockBubbleCard key={item.id} fileurl={item.fileurl} filename={item.filename}  deleteStock={this.handleDelete}/>
                                        )
                                        ) : null
                                }
                            </div>
                        </div>
                        <div className="col-sm-12 mb-5">
                            <div className="justify-content-center d-flex w-100 pt-5">
                                <Pagination data={this.state.stock_data} onPageChange={this.handlePageClick} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default StockList;
