import React, { Component} from 'react';
import { withRouter } from "react-router";
import { Link, NavLink } from "react-router-dom";
import Auth from '../../Services/Auth';
import helpers from '../../Helpers/Helper';
import GlobalContext from '../../Context/GlobalContext';

class Header extends Component {
    static contextType = GlobalContext;
    
    constructor(props, context) {
        super(props);
        
        this.state = {
            site_logo: context.settingDetails.site_logo,
            site_name: context.settingDetails.site_name,
            dummy_logo: context.settingDetails.dummy_logo,
            user_name: context.userDetails.name,
            role_id: context.userDetails.role_id,
            login_redirect: context.loginRedirect
        }

        this.handleLogout = this.handleLogout.bind(this);
        
    }
    handleLogout(e) {
        e.preventDefault();
        Auth.logout((response) => {
            if (response.data.success == true){
                setTimeout(() => {
                    window.location.pathname = this.state.login_redirect;
                }, 500);
            }
        }, 
        (err) => {
            helpers.toastAlert('error', "Error in logout.");
        });
    }
    menuCollapse = () => {
        document.body.classList.toggle('menu-toggle');
    }

    render() {
        
        return (
            <>
            <div className="top-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-6">
                            <div className="menu-collapse" onClick={this.menuCollapse} title="Menu Toggle">
                                <svg className="icon"><use href="#icon_stream"></use></svg>
                            </div>
                        </div>
                        <div className="col-6 col-dropdown">
                            <div className="dropdown d-flex justify-content-end">
                                <button className="btn-username dropdown-toggle pe-0" type="button" data-bs-toggle="dropdown">
                                    <svg className="icon"><use href="#icon_user"></use></svg>
                                    <span>{this.state.user_name}</span>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end border-0 shadow-lg" aria-labelledby="dropdownMenuButton">
                                    
                                    <li>
                                        <Link to={'/change-password'} className="dropdown-item py-2">
                                            <svg className="icon me-3"><use xlinkHref="#icon_lock"></use></svg>
                                            Change Password
                                        </Link>
                                    </li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li>
                                        <Link onClick={this.handleLogout}  className="dropdown-item py-2">
                                            <svg className="icon me-3"><use xlinkHref="#icon_logout"></use></svg>
                                            Logout
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="aside-bar">
                <div className="aside-logo">
                    <img src={(this.props.siteLogo?this.props.siteLogo:this.state.site_logo)+'?'+Date.now()} alt={this.state.site_name} onError={(e)=>{e.target.onerror = null; e.target.src=this.state.dummy_logo}}/>
                </div>
                <ul className="aside-menu">
                    <li>
                        <Link to={'/dashboard'} title="Dashboard" className={this.props.location.pathname == '/dashboard' ? 'active' : ''}>
                            <svg className="icon"><use href="#icon_dashboard"></use></svg>
                        Dashboard
                    </Link>
                    </li>
                    
                    <li>
                        <Link to="#" title="Caption" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapseExample" className={['/caption-images', '/comments', '/reports'].includes(this.props.location.pathname) ? 'collapsed-more active' : 'collapsed-more collapsed'}>
                            <svg className="icon"><use href="#icon_comments"></use></svg>
                            Caption
                        </Link>
                        <div className={['/caption-images', '/comments', '/reports', '/shared-by-mails'].includes(this.props.location.pathname) ? 'sub-menu-list collapse show' : 'collapse sub-menu-list'} id="collapse1">
                            <ul className="list-unstyled ps-3">
                                <li>
                                    <NavLink to={'/caption-images'} title="Images">
                                        <svg className="icon"><use href="#icon_images"></use></svg>
                                        Images
                                    </NavLink>
                                </li>
                                    {
                                        (this.state.role_id && this.state.role_id == 1) ?
                                        <>
                                            <li>
                                                <NavLink to={'/comments'} title="Comments">
                                                    <svg className="icon"><use href="#icon_commentsfill"></use></svg>
                                                    Comments
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={'/reports'} title="Reports">
                                                    <svg className="icon"><use href="#icon_report"></use></svg>
                                                    Reports
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={'/shared-by-mails'} title="Shared By Mails">
                                                    <svg className="icon"><use href="#icon_envelope"></use></svg>
                                                    Shared By Mails
                                                </NavLink>
                                            </li>
                                            </>
                                            : ''
                                    }
                                
                                
                            </ul>
                        
                        </div>
                    </li>
                        {
                            (this.state.role_id && this.state.role_id == 1) ?
                            <>
                            <li>
                                <Link to={'/stock-images'} title="Stock Images" className={['/stock-images', '/upload-stock-images'].includes(this.props.location.pathname) ? 'active' : ''}>
                                    <svg className="icon"><use href="#icon_images"></use></svg>
                                    Stock Images
                                </Link>
                            </li>
                            <li>
                                <Link to="#" title="Users" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapseExample" className={['/super-admins', '/admins', '/users'].includes(this.props.location.pathname) ? 'collapsed-more active' : 'collapsed-more collapsed'}>
                                    <svg className="icon"><use href="#icon_users"></use></svg>
                                    Users
                                </Link>
                                <div className={['/super-admins', '/admins', '/users'].includes(this.props.location.pathname) ? 'sub-menu-list collapse show' : 'collapse sub-menu-list'} id="collapse2">
                                    <ul className="list-unstyled ps-3">
                                        <li>
                                            <NavLink to={'/super-admins'} title="Super Admins">
                                                <svg className="icon"><use href="#icon_users"></use></svg>
                                                Super Admins
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/admins'} title="Admins">
                                                <svg className="icon"><use href="#icon_users"></use></svg>
                                                Admins
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/users'} title="Users">
                                                <svg className="icon"><use href="#icon_users"></use></svg>
                                                Users
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <Link to="#" title="Settings" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseExample" className={this.props.location.pathname.match(/^.*pages|settings|ads-banners.*$/) ? 'collapsed-more active' : 'collapsed-more collapsed'}>
                                    <svg className="icon"><use href="#icon_setting"></use></svg>
                                    Settings
                                </Link>
                                <div className={this.props.location.pathname.match(/^.*pages|settings|ads-banners.*$/) /*['/settings', '/pages/*', '/ads-banners', '/edit-ads-banners/*'].includes(this.props.location.pathname)*/ ? 'sub-menu-list collapse show' : 'collapse sub-menu-list'} id="collapse3">
                                    <ul className="list-unstyled ps-3">
                                        <li>
                                            <NavLink to={'/settings'} title="Site Settings">
                                                <svg className="icon"><use href="#icon_setting"></use></svg>
                                                Site Settings
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/pages/list'} title="Pages" isActive={() => {if (this.props.location.pathname.match(/^.*pages.*$/)) {return true;}}}>
                                                <svg className="icon"><use href="#icon_link"></use></svg>
                                                Pages
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/ads-banners/list'} title="Ads Banners" isActive={() => {if (this.props.location.pathname.match(/^.*ads-banners.*$/)) {return true;}}}>
                                                <svg className="icon"><use href="#icon_ad"></use></svg>
                                                Ads Banners
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            </>
                            : ''
                        }
                </ul>
            </div>
            </>
        )
    }
}

export default withRouter(Header);

