import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Comment from '../../Services/Comment';
import Datatable from '../components/Datatable';
import helpers from '../../Helpers/Helper';
import Loader from '../../Loader';

const setPageTitle = (title) => {
    const prevTitle = document.title;
    document.title = title;
    return () => document.title = prevTitle;
}

class CommentList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dt_data: {
                dt_url: Comment.list(),
                dt_column: [
                    { "data":"id" , "title": "ID"},
                    { "data":"image_id", "title":"Caption Image" },
                    { "data":"user_name", "title":"Username" },
                    { "data":"comment", "title":"Comment" },
                    { "data":"status", "title":"Status" },
                    { "data":"id", "title":"Action" },
                    { "data":"status_id" },
                ],
                dt_column_defs: [
                    {
                        targets: 6,
                        "visible": false,
                    },
                    {
                        targets: 5,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(<>
                                { 
                                    rowData.status_id == 2 ?
                                    <><button type="button" className="btn btn-primary btn-sm" onClick={() => this.handleStatus({id: rowData.id, status: 1})}><svg className="icon"><use href="#icon_activate"></use></svg></button> &nbsp;&nbsp;</>
                                    :
                                    <><button type="button" className="btn btn-secondary btn-sm" onClick={() => this.handleStatus({id: rowData.id, status: 2})}><svg className="icon"><use href="#icon_cancel"></use></svg></button> &nbsp;&nbsp;</>
                                }
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => this.handleDelete(rowData.id)}><svg className="icon"><use href="#icon_delete"></use></svg></button>
                            </>, td),
                    }
                ],
                dt_paging: true,
            },
            isLoading: true
        }

        this.handleDelete = this.handleDelete.bind(this);
        this.datatable_reload = React.createRef();
    }

    componentDidMount() {
        this.setState({ isLoading: false})
    }

    handleDelete(id){
        var r = window.confirm("Delete this comment?");
        if (r == true) {
            Comment.delete({ id: id }, (response) => {
                if (response.status == 200) {
                    helpers.toastAlert('success', response.data.message);
                    this.datatable_reload.current.loadDataTable();
                } else {
                    helpers.toastAlert('error', response.data.message);
                }
            }, (err) => {
                console.log(err)
            })
        }
    }

    handleStatus(data){
        var r = window.confirm("Are you sure, you want to change status?");
        if (r == true) {
            Comment.status(data, (response) => {
                if (response.status === 200) {
                    helpers.toastAlert('success', response.data.message);
                    this.datatable_reload.current.loadDataTable();
                } else {
                    helpers.toastAlert('error', response.data.message);
                }
            }, (err) => {
                console.log(err);
            });
        }
    }

    render(){
        setPageTitle('Comments');
        return (
            this.state.isLoading ? <Loader/>
            : 
            <>
            <div className="main-wrapper">
                <div className="container-fluid container-common">
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <h2 className="page-heading">
                            Comments
                            </h2>
                        </div>
                        
                        <div className="col-sm-12 mt-4">
                            <div className="userlist bg-white p-5">
                                <Datatable dt_data={this.state.dt_data} ref={this.datatable_reload}></Datatable>
                            </div>
                        </div>
                        
                        <div className="col-sm-12 mb-5">
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default CommentList;
