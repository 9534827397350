import React, { Component } from 'react';
import Caption from '../Services/Caption';
import Comment from '../Services/Comment';
import helpers from '../Helpers/Helper';
import {TwitterShareButton} from "react-share";
import Cookies from 'js-cookie'
import GlobalContext from '../Context/GlobalContext';
import Loader from '../Loader';
import {Helmet} from "react-helmet";

import { loadCaptchaEnginge, LoadCanvasTemplate,validateCaptcha } from 'react-simple-captcha';

class PreviewShare extends Component {
    static contextType = GlobalContext;

    constructor(props, context) {
        super(props);
        // create a ref to store the DOM element
        this.state = {
            site_name: context.settingDetails.site_name,
            caption_data: '',
            caption_list: '',
            caption_comments: '',
            like_count: 0,
            share: 0,
            ads_code: '',
            temp_url: props.match.params.slug,
            current_url: window.location.href,
            isLoading: true,
            like: '', 
            likeStatus:1
        }

        this.commentRef = React.createRef()

        this.handleAddComment = this.handleAddComment.bind(this);
        this.handleAddReport = this.handleAddReport.bind(this);
        this.handleShareByMail = this.handleShareByMail.bind(this);
        this.updateLikeStatus      = this.updateLikeStatus.bind(this);
    }

    scrollToComment = () => window.scrollTo(0, this.commentRef.current.offsetTop)

    async componentDidMount() {
        var ua = navigator.userAgent;
        if(/^(facebookexternalhit)|(Twitterbot)|(Pinterest)/gi.test(ua)){
            window.location.href = Caption.caption_meta_web_url(this.state.temp_url);
        }

        let cids_cookies = Cookies.get('cids');

        if (cids_cookies && cids_cookies.includes(this.state.temp_url)){
            this.setState({
                like: 'active',
                likeStatus: 2
            })
        }

        this.captionDetails();
    }

    captionDetails(){
        Caption.detail(this.state.temp_url, (res) => {
            if (res.data) {
                this.setState({
                    caption_data: res.data.data,
                    like_count: res.data.data.likes,
                    share: res.data.data.share,
                    caption_list: res.data.caption_list,
                    caption_comments: res.data.caption_comments,
                    ads_code: res.data.ads_code,
                    isLoading: false
                })

                loadCaptchaEnginge(6);

                document.getElementById('reload_href').innerHTML = '<svg class="icon"><use xlink:href="#icon_refresh"></use></svg>';
            }
        }, (err) => {
            console.log(err)
        });
    }

    captionDelete(captionId){
        if ((localStorage.getItem('user_info') &&
            JSON.parse(localStorage.getItem('user_info')).role_id == 1)) {

            var r = window.confirm("Delete this caption?");
            if (r == true) {
                this.setState({ isLoading: true });
                Caption.delete({id:captionId}, (response) => {
                    if (response.status == 200) {
                        helpers.toastAlert('success', response.data.message);
                        this.props.history.push({
                            pathname: '/gallery',
                        });
                    } else {
                        helpers.toastAlert('error', response.data.message);
                    }
                }, (err) => {
                    helpers.toastAlert('error', 'Something went wrong!');
                })
            }
        }
    }

    handleAddComment(e) {
        e.preventDefault();

        let user_captcha = document.getElementById('captcha');
        if (validateCaptcha(user_captcha.value) == false) {
            helpers.toastAlert('error', 'Captcha verifiication failed')
            user_captcha.value = "";
            return 
        }

        const formData = new FormData(e.target);
        formData.append("temp_url", this.state.temp_url);

        Caption.add_comment(formData, (res) => {
            helpers.toastAlert('success', res.data.message);
            e.target.reset();
        }, (err) => {
            let errorObj = err.response.data.errors;
            Object.keys(errorObj).map((item) =>
                errorObj[item].map((message) =>
                    helpers.toastAlert('error', message))
            )
        })
        loadCaptchaEnginge(6);
    }

    deleteComment(commentId){
        if ((localStorage.getItem('user_info') && 
        JSON.parse(localStorage.getItem('user_info')).role_id == 1)){

            var r = window.confirm("Delete this comment?");
            if (r == true) {
                Comment.delete({ id: commentId }, (response) => {
                    if (response.status == 200) {
                        helpers.toastAlert('success', response.data.message);
                        this.captionDetails();
                    } else {
                        helpers.toastAlert('error', response.data.message);
                    }
                }, (err) => {
                    helpers.toastAlert('error', 'Something went wrong!');
                })
            }
        }else{
            helpers.toastAlert('error', 'Unauthorised to perform this action!');
        }
    }

    handleAddReport(e) {
        e.preventDefault();

        const formData = new FormData(e.target);
        formData.append("temp_url", this.state.temp_url);
        Caption.add_report(formData, (res) => {
            helpers.toastAlert('success', res.data.message);
            e.target.reset();
            document.querySelector('#reportModal [data-bs-dismiss="modal"]').click();
        }, (err) => {
            let errorObj = err.response.data.errors;
            Object.keys(errorObj).map((item) =>
                errorObj[item].map((message) =>
                    helpers.toastAlert('error', message))
            )
        })
    }

    handleShareByMail(e) {
        e.preventDefault();

        const formData = new FormData(e.target);
        formData.append("temp_url", this.state.temp_url);
  
        Caption.add_shareByMail(formData, (res) => {
            helpers.toastAlert('success', res.data.message);
            e.target.reset();
            this.setState({ share: res.data.share.share})
            document.querySelector('#sendbymailModal [data-bs-dismiss="modal"]').click();
            this.mailTo(formData)
        }, (err) => {
            this.setState({ isLoading: false })
            let errorObj = err.response.data.errors;
            Object.keys(errorObj).map((item) =>
                errorObj[item].map((message) =>
                    helpers.toastAlert('error', message))
            )
        })
    }

    mailTo = (data) => {
        const mailto  = "mailto:"+data.get('recipients_email')+"?subject="+data.get('name')+" has shared a caption with you&body="+data.get('name')+" has shared a caption photo with you with the following message:%0A%0A"+data.get('description')+"%0A%0AClick on the following link to see the caption photo: "+window.location.href+"%0A%0A%0A%0AThank you!";
        window.location.href = mailto;
    }

    updateLikeStatus(likeStatus){
        Caption.like_dislike({
                temp_url: this.state.temp_url, status: likeStatus
            }, (response) => {
                if (response.status === 200) {
                    let cids_cookies = Cookies.get('cids');
                    if (likeStatus == 1) {

                        if (cids_cookies) {
                            let temp_url = this.state.temp_url;
                            var cids_arr = JSON.parse(cids_cookies)
                            cids_arr.push(temp_url);
                            Cookies.set('cids', JSON.stringify(cids_arr.filter((v, i, a) => a.indexOf(v) === i)));
                        } else {
                            let temp_url = this.state.temp_url;
                            let cids_arr = [];
                            cids_arr.push(temp_url);
                            Cookies.set('cids', JSON.stringify(cids_arr));
                        }
                        this.setState({ likeStatus: 2, like: 'active', like_count: response.data.data})
                    } else {
                        var arr = JSON.parse(cids_cookies)
                        const index = arr.indexOf(this.state.temp_url);
                        if (index > -1) {
                            arr.splice(index, 1);
                        }
                        Cookies.set('cids', JSON.stringify(arr));
                        this.setState({ likeStatus: 1, like: '', like_count: response.data.data})
                    }
                }
            }, (err) => {
                console.log(err);
        });
     }

    render() {
        return (
            <>
            <Helmet>
                <title>{this.state.caption_data.meta_title && this.state.caption_data.meta_title.length > 60 ? this.state.caption_data.meta_title.substr(0, 60)+'...' : this.state.caption_data.meta_title}</title>
                <meta name="description" content={this.state.caption_data.meta_desc} />
            </Helmet>
            {this.state.isLoading ? <Loader/>
            :
            <>
                <div className="share-listing">
                    <button type="button">
                        <svg className="icon"><use xlinkHref="#icon_eye"></use></svg>
                        <span>{this.state.caption_data.views}</span>
                    </button>
                    <button type="button">
                        <svg className="icon"><use xlinkHref="#icon_share"></use></svg>
                        <span>{this.state.share}</span>
                    </button>
                    <button type="button" className={this.state.like} onClick={() => this.updateLikeStatus(this.state.likeStatus)}>
                        <svg className="icon"><use href="#icon_heart"></use></svg>
                        <span>{this.state.like_count}</span>
                    </button>
                    <button type="button" onClick={this.scrollToComment}>
                        <svg className="icon"><use xlinkHref="#icon_comments"></use></svg>
                        <span>{this.state.caption_data.comment}</span>
                    </button>
                    {/* <button type="button"><svg className="icon"><use href="#icon_facebook"></use></svg></button> */}
                    <TwitterShareButton
                        url={this.state.current_url}
                        title={this.state.caption_data.caption}
                    >
                        <svg className="icon"><use href="#icon_twitter"></use></svg>
                    </TwitterShareButton>
                    {/* <button type="button"><svg className="icon"><use href="#icon_pinterest"></use></svg></button> */}
                    <button type="button" data-bs-toggle="modal" data-bs-target="#sendbymailModal">
                        <svg className="icon"><use href="#icon_envelope"></use></svg>
                    </button>
                    <button type="button" data-bs-toggle="modal" data-bs-target="#reportModal">
                        <svg className="icon"><use href="#icon_exclamation2"></use></svg>
                    </button>
                    {
                    (localStorage.getItem('user_info') && JSON.parse(localStorage.getItem('user_info')).role_id == 1) ?
                            <button type="button" title="Delete" onClick={(e) => this.captionDelete(this.state.caption_data.id)}>
                            <svg className="icon"><use href="#icon_delete"></use></svg>
                        </button>
                        : ''
                    }
                   
                </div>

                {/* Modal | Send by mail */}
                <div className="modal fade" id="sendbymailModal" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <form onSubmit={this.handleShareByMail} id="sendbymailModalForm">
                                <div className="modal-header">
                                    <h5 className="modal-title">Send By Mail</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label htmlFor="yourname" className="form-label small mb-1">Your name</label>
                                        <input type="text" name="name" className="form-control" id="yourname" placeholder="Your name" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="emailaddress" className="form-label small mb-1">Your email address</label>
                                        <input type="email" name="email" className="form-control" id="emailaddress" placeholder="name@example.com" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="recemailaddress" className="form-label small mb-1">
                                            Recipient email <small className="text-muted"> - separate multiple addresses with commas (, )</small>
                                        </label>
                                        <input type="text" name="recipients_email" className="form-control" id="recemailaddress" placeholder="email_1@example.com, email_2@example.com" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="messagetext1" className="form-label small mb-1">Your message</label>
                                        <textarea name="description" className="form-control" id="messagetext1" rows="4"></textarea>
                                        <div id="emailHelp" className="form-text text-danger">We'll never share your email with anyone else.</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-light border" data-bs-dismiss="modal">Cancel</button>
                                    <button type="submit" className="btn btn-primary theme-btn px-4">Send</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                {/* Modal | Report this image */}
                <div className="modal fade" id="reportModal" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <form onSubmit={this.handleAddReport} id="reportModalForm">
                                <div className="modal-header">
                                    <h5 className="modal-title">Report this image</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p>
                                    We are doing our very best to keep the site safe for use, it is beyond our technical capabilities to review every single caption image uploaded to our website, but we will review any image report within 72 hours.
                                    </p>
                                    <p>
                                    If you have any issues with the content of this image, we will review the request and actions will be taken to ensure this image stands in our community's standards.
                                    </p>
                                    <div className="mb-3">
                                        <label htmlFor="emailaddress" className="form-label small mb-1">Your email address</label>
                                        <input type="email" className="form-control" name="email" id="emailaddress" placeholder="name@example.com"/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="messagetext" className="form-label small mb-1">Your message</label>
                                        <textarea className="form-control" id="messagetext" rows="4" name="description"></textarea>
                                        <div id="emailHelp" className="form-text text-danger">We'll never share your email with anyone else.</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-light border" data-bs-dismiss="modal">Cancel</button>
                                    <button type="submit" className="btn btn-primary theme-btn">Report Image</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="container-fluid container-preview">
                    <div className="row">
                        <div className="col-sm-12 px-0">
                            <img src={this.state.caption_data.image_path} className="w-100" alt={this.state.caption_data.caption} onError={(e) => {e.target.onerror = null; e.target.src = this.state.caption_data.dummy_image}}/>
                        </div>
                        <div className="col-sm-12 text-center text-uppercase px-5">
                            <h2 className="py-5 m-0 px-5">
                                { this.state.caption_data.bubble_type == 2 ?
                                    <div dangerouslySetInnerHTML={{
                                        __html: this.state.caption_data.meta_title
                                    }}>
                                    </div>
                                    :
                                    this.state.caption_data.meta_title.length > 60 ? this.state.caption_data.meta_title.substr(0, 60)+'...' : this.state.caption_data.meta_title
                                }
                            </h2>
                            { this.state.caption_data.bubble_type == 2 ?
                                <div className="fs-2 px-5">
                                    <div dangerouslySetInnerHTML={{
                                            __html: this.state.caption_data.meta_desc
                                        }}>
                                    </div>
                                </div>
                                :''
                            }
                            <hr className="mt-0"/>
                        </div>
                    </div>
                    {/* Start | Comment Field Area */}
                    <div className="row justify-content-center" ref={this.commentRef}>
                        <div className="col-sm-12 col-md-8">
                            {
                                this.state.caption_comments ? (
                                this.state.caption_comments.map((item)=>
                                <ul className="comment-listing" id="listadd" key={item.id}>
                                <li>
                                    <p>
                                        {item.comment}
                                    </p>
                                    <span>{item.user_name}</span>
                                    {
                                        (localStorage.getItem('user_info') && JSON.parse(localStorage.getItem('user_info')).role_id == 1)?
                                                    <button type="button" title="Remove" onClick={(e) => this.deleteComment(item.id)}>Remove</button>
                                        :''
                                    }
                                </li>
                                </ul>
                                )
                                ) : ''
                            }
                            
                            <form className="py-4" onSubmit={this.handleAddComment}>
                                <div className="mb-3 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                                    <input type="text" className="form-control" id="username" name="user_name" placeholder="Enter your name" spellCheck="false" autoComplete="off"/>
                                </div>
                                <div className="mb-3">
                                    <textarea className="form-control" id="commenttext" name="comment" placeholder="Write your comment here..." rows="4"></textarea>
                                </div>
                                <div className="mb-3 col-sm-6 col-md-5 col-lg-5 col-xl-5 captcha-control">
                                    <LoadCanvasTemplate />
                                    <input type="text" className="form-control" id="captcha" name="captcha" placeholder="Enter captcha code" spellCheck="false" autoComplete="off" />
                                </div>
                                <button type="submit" className="btn btn-primary theme-btn px-4 mt-2">Submit</button>
                            </form>
                        </div>
                    </div>
                    {/* End | Comment Field Area */}
                </div>
                <div className="thumb-listing hide-listing" id="preview-box">
                    <div className="ads-code s300x600 mb-3">
                        <div className="ads-title text-center">
                            Advertisement
                        </div>
                        <div className="ads-content" dangerouslySetInnerHTML={{
                                            __html: this.state.ads_code.script
                                        }}>
                        </div>
                    </div>
                    {
                        this.state.caption_list.data ? (
                        this.state.caption_list.data.map((item)=>
                        <a href={"show/"+item.temp_url} className="cart-thumbbox mb-3" key={item.id}>
                            <img src={item.image_path} alt={item.caption} onError={(e) => {e.target.onerror = null; e.target.src = item.dummy_image}}/>
                        </a>
                        )
                        ) : null
                    }
                </div>
            </>
            }
            </>
        )
    }
}

export default PreviewShare;
