import axios from "axios";

let API_URL = process.env.REACT_APP_API_URL;

const Page = {

    list: () => {
        return API_URL + 'get-page';
    },

    get: (successCb, failCb) => {
        return axios.get(API_URL + 'get-page-list').then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    add: (data, successCb, failCb) => {
        axios.post(API_URL + 'add-page', data, { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } }).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },
    update: (data, successCb, failCb) => {
        axios.post(API_URL + 'update-page', data, { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } }).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },
    detail: (data, successCb, failCb) => {
        return axios.post(API_URL + 'edit-page', data, { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } }).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    delete: (data, successCb, failCb) => {
        axios.post(API_URL + 'delete-page', data, { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } }).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    pageData: (data,successCb, failCb) => {
        axios.post(API_URL + 'page-detail', data).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

};

export default Page;