import React, { Component } from 'react';
import Caption from '../../Services/Caption';
import CaptionCard from '../components/CaptionCard';
import helpers from '../../Helpers/Helper';
import Masonry from 'masonry-layout/dist/masonry.pkgd';
import Pagination from '../../Helpers/Pagination';
import Loader from '../../Loader';
import imagesLoaded from 'imagesloaded/imagesloaded.pkgd';

const MasonryInt = () => {
    var grid = document.querySelector('#row_masonry');
    var imgLoad = imagesLoaded(grid);

    function onAlways( instance ) {
        var msnry = new Masonry(grid, {
            percentPosition: true,
            itemSelector: '.col',
        });
        msnry.layout()

        document.querySelector('.body-bubbleerror').style.display = "none";
    }
    imgLoad.on( 'always', onAlways );  
}

const setPageTitle = (title) => {
    const prevTitle = document.title;
    document.title = title;
    return () => document.title = prevTitle;
}

class CaptionList extends Component {
    
    constructor(props) {
        super(props);
        // create a ref to store the DOM element
        this.state = {
            caption_data: '',
            sortBy: 'newest',
            pageCount: 1,
			currentPage: 1,
            isLoading: true
        }
        
        this.handlePageClick = this.handlePageClick.bind(this);
        this.sortCaption     = this.sortCaption.bind(this);
        this.handleDelete    = this.handleDelete.bind(this);
        this.handleStatus    = this.handleStatus.bind(this);
    }

    componentDidUpdate(){
        document.querySelector('.body-bubbleerror').style.display = "block";
        if(this.state.isLoading == false){
            MasonryInt()
        }
    }
    componentDidMount() {
        this.listData()
    }

    async handlePageClick(data) {
        this.setState({ isLoading: true });
        const page = data.selected >= 0 ? data.selected + 1 : 0;
		await Promise.resolve(this.setState(() => ({ currentPage: page })));

		this.listData()
	}
    
    async listData() {
        Caption.list(this.state.currentPage, this.state.sortBy).then(response => {
            this.setState({ caption_data: response.data, isLoading: false });
        }).catch(err => {
            console.log(err);
        });
    }

    handleStatus(data){
        this.setState({ isLoading: true });
        Caption.status(data, (response) => {
            if (response.status === 200) {
                helpers.toastAlert('success', response.data.message);
                this.listData();
            }
        }, (err) => {
            console.log(err);
        });
    }

    async sortCaption(sortBy){
        this.setState({ isLoading: true });
        await Promise.resolve(this.setState(() => ({ sortBy: sortBy })));
        this.listData();
    }

    handleDelete(id){
        var r = window.confirm("Delete this caption?");
        if (r == true) {
            this.setState({ isLoading: true });
            Caption.delete(id, (response) => {
                if (response.status == 200) {
                    helpers.toastAlert('success', response.data.message);
                    this.listData(this.state.currentPage)
                } else {
                    helpers.toastAlert('error', response.data.message);
                }
            }, (err) => {
                console.log(err);
            })
        }
    }

    render(){
        setPageTitle('Caption Image');
        return (
            
            <>
            <Loader/>
            
            <div className="main-wrapper">
                <div className="container-fluid container-common">
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <h2 className="page-heading">
                                Caption Images 
                            </h2>
                        </div>
                        
                        <div className="col-sm-12 mt-2">
                            <div className="row">
                                <div className="col-sm-12 col-12">
                                    <div className="d-flex justify-content-end caption-sort">
                                        <button onClick={() => this.sortCaption('newest')} className={this.state.sortBy == 'newest' ? 'active me-2' : 'me-2'}>Newest</button> |
                                        <button onClick={() => this.sortCaption('view')} className={this.state.sortBy == 'view' ? 'active ms-2' : 'ms-2'}>Popular</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6 gy-4 mt-0" data-masonry='{"percentPosition": true }' id="row_masonry">
                                {
                                    this.state.caption_data.data ? (
                                    this.state.caption_data.data.map((item)=>
                                        <CaptionCard key={item.id} caption_data={item} updateStatus={this.handleStatus}  deleteCaption={this.handleDelete}/>
                                    )
                                    ) : null
                                }
                            </div>
                        </div>
                        <div className="col-sm-12 mb-5">
                            <div className="justify-content-center d-flex w-100 pt-5">
                                <Pagination data={this.state.caption_data} onPageChange={this.handlePageClick} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default CaptionList;
