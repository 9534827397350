import React, { Component } from 'react';

class CaptionCard extends Component{
    constructor(props){
        super(props);
        
        this.handleCaptionStatus = this.handleCaptionStatus.bind(this); 
        this.deleteCaptionImage = this.deleteCaptionImage.bind(this); 
    }   
    handleCaptionStatus(status) {
        this.props.updateStatus({
            id: this.props.caption_data.id,
            status: status
        });
    } 

    deleteCaptionImage(e){
        this.props.deleteCaption({
            id: this.props.caption_data.id
        });
    }

    render(){
        return(
            <div className="col">
                <div className="card card-box shadow-sm bg-body rounded overflow-hidden border-0 position-relative">
                    <div className="card-imgbox">
                        <img src={this.props.caption_data.image_path} alt={this.props.caption_data.caption} onError={(e) => {e.target.onerror = null; e.target.src = this.props.caption_data.dummy_image}}/>
                    </div>
                    {this.props.caption_data.caption != null ?
                        <div className="card-body">
                            <ul className="card-bubbleaction">
                                <li>
                                    <svg className="icon"><use xlinkHref="#icon_eye"></use></svg>
                                    <span>{this.props.caption_data.views}</span>
                                </li>
                                <li>
                                    <svg className="icon"><use xlinkHref="#icon_heartline"></use></svg>
                                    <span>{this.props.caption_data.likes}</span>
                                </li>
                                <li>
                                    <svg className="icon"><use xlinkHref="#icon_share"></use></svg>
                                    <span>{this.props.caption_data.share}</span>
                                </li>
                                <li>
                                    <svg className="icon"><use xlinkHref="#icon_comments"></use></svg>
                                    <span>{this.props.caption_data.comment}</span>
                                </li>
                            </ul>
                            <p className="card-text">{this.props.caption_data.caption}</p>
                            <div className="d-flex justify-content-evenly">
                                {this.props.caption_data.status == 2 ? 
                                    <button type="button" className="card-link btn btn-success btn-sm" onClick={() => this.handleCaptionStatus(1)}><svg className="icon me-1"><use href="#icon_activate"></use></svg>Approve</button>
                                    :
                                    <button type="button" className="card-link btn btn-secondary btn-sm" onClick={() => this.handleCaptionStatus(2)}><svg className="icon me-1"><use href="#icon_cancel"></use></svg>Disapproved</button>
                                }
                                <button type="button" className="card-link btn btn-danger btn-sm" onClick={this.deleteCaptionImage}><svg className="icon me-1"><use href="#icon_delete"></use></svg>Delete</button>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        )
    }
}
export default CaptionCard;