import React from 'react';
import {Route, Switch, BrowserRouter, Redirect} from "react-router-dom";
import {AdvertiseList, AdvertiseEdit} from "../Advertisement/Index";
import {Settings, ChangePassword} from "../Settings/Index";
import {PageList, PageEdit} from "../Pages/Index";
import CommentList from "../Comments/CommentList";
import ReportList from "../Reports/ReportList";
import {StockList, UploadStock} from "../Stock/Index";
import Dashboard from "../Dashboard/Index";
import { UserList, AddUser} from "../Users/Index";
import {CaptionList} from "../Caption/Index";
import NotFound from "../../Error/NotFound";
import AuthenticatedRoute from '../components/AuthenticatedRoute';
import ShareByMailList from '../ShareByMail/ShareByMailList';

class AdminRoutes extends Route { 
    constructor(props) {
      super(props);

        this.state = {
            role_id: localStorage.getItem('user_info') ? JSON.parse(localStorage.getItem('user_info')).role_id : '',
            path : props.path
        }
    }
  
    render() {
        return (
            <>
            {
                this.state.path == '/admin' ? 
                <>
                <BrowserRouter basename={"/admin"}>
                    
                    <Switch>
                    
                        {/* Dashboard Routes */}
                        <AuthenticatedRoute path={'/dashboard'} roleId={1} component={Dashboard} />

                        {/* Change Password */}
                        <AuthenticatedRoute path={'/change-password'} roleId={1} component={ChangePassword} />

                        {/* Comment Routes */}
                        <AuthenticatedRoute path={'/caption-images'} roleId={1} component={CaptionList} />

                        {/* Comment Routes */}
                        <AuthenticatedRoute path={'/comments'} roleId={this.state.role_id} component={CommentList} />

                        {/* Report Routes */}
                        <AuthenticatedRoute path={'/reports'} roleId={this.state.role_id} component={ReportList} />

                        {/* Comment Routes */}
                        <AuthenticatedRoute path={'/shared-by-mails'} roleId={this.state.role_id} component={ShareByMailList} />
                        
                        {/* Stock Image Routes */}
                        <AuthenticatedRoute path={'/stock-images'} roleId={this.state.role_id} component={StockList} />
                        <AuthenticatedRoute path={'/upload-stock-images'} roleId={this.state.role_id} component={UploadStock} />

                        {/* User Routes */}
                        <AuthenticatedRoute path={'/users'} roleId={this.state.role_id} component={props => <UserList {...props} />} />
                        <AuthenticatedRoute path={'/admins'} roleId={this.state.role_id} component={props => <UserList {...props} />} />
                        <AuthenticatedRoute path={'/super-admins'} roleId={this.state.role_id} component={props => <UserList {...props} />} />
                        <AuthenticatedRoute path={'/add-user'} roleId={this.state.role_id} component={AddUser} />
                      
                        {/* Settings Routes */}
                        <AuthenticatedRoute path={'/settings'} roleId={this.state.role_id} component={Settings} />

                        {/* Pages Routes */}
                        <AuthenticatedRoute path={'/pages/list'} roleId={this.state.role_id} component={PageList} />
                        <AuthenticatedRoute path={'/pages/edit/:id'} roleId={this.state.role_id} component={PageEdit} />

                        {/* Ads Banner Routes */}
                        <AuthenticatedRoute path={'/ads-banners/list'} roleId={this.state.role_id} component={AdvertiseList} />
                        <AuthenticatedRoute path={'/ads-banners/edit/:id'} roleId={this.state.role_id} component={AdvertiseEdit} />
                
                        <AuthenticatedRoute roleId={this.state.role_id} component={NotFound} />
                    </Switch>
                    
                </BrowserRouter>
                </>
                :
                ''
            }
        </>
        )
    }
}
  
export default AdminRoutes
