import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SharedByMail from '../../Services/SharedByMail';
import Datatable from '../components/Datatable';
import helpers from '../../Helpers/Helper';
import Loader from '../../Loader';

const setPageTitle = (title) => {
    const prevTitle = document.title;
    document.title = title;
    return () => document.title = prevTitle;
}

class ShareByMailList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dt_data: {
                dt_url: SharedByMail.list(),
                dt_column: [
                    { "data":"id" , "title": "ID"},
                    { "data":"image_id", "title":"Caption Image" },
                    { "data":"name", "title":"Username" },
                    { "data":"email", "title":"Email" },
                    { "data":"recipients_email", "title":"Recipients Email" },
                    { "data":"description", "title":"Description" },
                    { "data":"id", "title":"Action" },
                ],
                dt_column_defs: [
                    {
                        targets: 6,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(<>
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => this.handleDelete(rowData.id)}><svg className="icon"><use href="#icon_delete"></use></svg></button>
                            </>, td),
                    }
                ],
                dt_paging: true,
            },
            isLoading: true
        }

        this.handleDelete = this.handleDelete.bind(this);
        this.datatable_reload = React.createRef();
    }

    componentDidMount() {
        this.setState({ isLoading: false})
    }

    handleDelete(id){
        var r = window.confirm("Delete this record?");
        if (r == true) {
            SharedByMail.delete({ id: id }, (response) => {
                if (response.status == 200) {
                    helpers.toastAlert('success', response.data.message);
                    this.datatable_reload.current.loadDataTable();
                } else {
                    helpers.toastAlert('error', response.data.message);
                }
            }, (err) => {
                console.log(err)
            })
        }
    }

    render(){
        setPageTitle('Shared By Mails');
        return (
            this.state.isLoading ? <Loader/>
            : 
            <>
            <div className="main-wrapper">
                <div className="container-fluid container-common">
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <h2 className="page-heading">
                            Shared By Mails
                            </h2>
                        </div>
                        
                        <div className="col-sm-12 mt-4">
                            <div className="userlist bg-white p-5">
                                <Datatable dt_data={this.state.dt_data} ref={this.datatable_reload}></Datatable>
                            </div>
                        </div>
                        
                        <div className="col-sm-12 mb-5">
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default ShareByMailList;
