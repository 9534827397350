import React, { Component } from 'react';
import Stock from '../../Services/Stock';
import helpers from '../../Helpers/Helper';
import Loader from '../../Loader';

class UploadStock extends Component {
    constructor(props) {
        super(props);
        // create a ref to store the DOM element
        this.state = {
            attachments: [],
            isLoading: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this)
    }

    handleInputChanged(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({isLoading: true});
        const formData = new FormData();

        Object.keys(this.state.attachments).forEach((key) => {
            formData.append('attachments[]', this.state.attachments[key]);
        })
        Stock.add(formData, (response) => {
            if (response.status == 201) {
                if(response.data.uploaded > 0 && response.data.failed == 0){
                    helpers.toastAlert('success', response.data.uploaded + ' files uploaded successfully.');
                }
                
                if(response.data.failed > 0){
                    helpers.toastAlert('error', 'Unable to upload ' + response.data.failed + ' files, file format should be in '+ response.data.file_type + '.')
                }

                if(response.data.size_error > 0){
                    helpers.toastAlert('error', 'Unable to upload ' + response.data.size_error + ' files. Minimum image size would be 640x480.')
                }
                
                this.setState({
                    attachments: ''
                })
                this.props.history.goBack();
            } else {
                helpers.toastAlert('error', response.data.message)
            }
        }, (err) => {
            let errorObj = err.response.data.errors;
            helpers.toastAlert('error', errorObj)
        })
    }
   
    async componentDidMount() {
      this.setState({isLoading: false});
    }

    onChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;

        this.setState({
            attachments: e.target.files,
        })
    }

    render() {
        return (
            this.state.isLoading ? <Loader/>
            : 
            <>
            <div className="main-wrapper">
                <div className="container-fluid container-common">
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <h2 className="page-heading">
                            Upload Stock Images
                            </h2>
                        </div>
                        <div className="mt-2">
                            <div className="d-flex justify-content-end"><button onClick={this.props.history.goBack} className="btn btn-dark px-3 btn-sm"><svg className="icon me-2"><use href="#icon_arrowleft"></use></svg> Back</button></div>
                        </div>
                        
                        <div className="col-sm-8 mt-4">
                            <div className="add-listing bg-white p-5">
                                <form onSubmit={this.handleSubmit} method="post">
                                    <div className="row g-5 mb-5">
                                        <div className="col">
                                            <input type="file" id="attachments" name="attachments" multiple onChange={this.onChange} className="form-control" placeholder="Setting Name" aria-label="Setting Name"/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <button type="submit" className="btn btn-danger btn-lg px-3 me-2">
                                                <svg className="icon me-2"><use href="#icon_submit"></use></svg> Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                        <div className="col-sm-12 mb-5">
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default UploadStock;