import React, { Component} from 'react';
import _ from 'lodash';
import { Link, NavLink, withRouter } from "react-router-dom";
import Setting from '../../Services/Setting';
import helpers from '../../Helpers/Helper';
import GlobalContext from '../../Context/GlobalContext';
import {Helmet} from "react-helmet";

class Header extends Component {
    static contextType = GlobalContext;

    constructor(props, context) {
        super(props);

        this.state = {
            site_logo: context.settingDetails.site_logo,
            site_name: context.settingDetails.site_name,
            dummy_logo: context.settingDetails.dummy_logo,
            searchBy: props.location.search ? _.split(props.location.search, '?s=')[1] : '',
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        Setting.getSettings((res) => {
            if (res.data) {
                localStorage.setItem("site_data", JSON.stringify(res.data.data));
                this.setState({
                    isLoading: false, 
                    site_name: res.data.data.site_name,
                    site_logo: res.data.data.site_logo
                })
            }
        }, (err) => {
            console.log(err)
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        
        const formData = new FormData(e.target);
        
        if(_.size(formData.get('s')) > 2){
            this.setState({searchBy: formData.get('s')});
            this.props.history.push({
                pathname: '/search',
                search: '?s='+formData.get('s')
            });
        }else if(_.size(formData.get('s')) == 0){
            this.props.history.push({
                pathname: '/',
            });
        }else{
            helpers.toastAlert('error', "Query length must be greater than 3.");
        }
    }

    render(){
        return (
            <>
            <Helmet>
                <title>{this.props.location.pathname.match(/^.*gallery.*$/) ? 'View All Captions' : ( this.props.location.pathname.match(/^.*search.*$/) ? this.state.searchBy + ' Search Results...' : this.state.site_name)}</title>
                <meta name="description" content={this.state.site_name} />
            </Helmet>
            <header>
                <nav className="navbar navbar-expand-lg p-0">
                    <div className="container-fluid pe-0">
                    <Link to="/" className="logo-caption navbar-brand text-white fs-3 fw-bolder p-0"><img src={this.state.site_logo} alt={this.state.site_name} onError={(e)=>{e.target.onerror = null; e.target.src=this.state.dummy_logo}}/></Link>
                    
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown">
                            <span className="navbar-toggler-icon pt-1">
                                <svg className="icon fs-1"><use href="#icon_menu-bar"></use></svg>
                            </span>
                        </button>
                        
                        <div className="collapse navbar-collapse menu-option" id="navbarNavDropdown">
                        <div className="search-bar d-md-block d-lg-block pt-2 pb-2">
                            <form onSubmit={this.handleSubmit} method="post">
                                <div className="input-group">
                                    <input type="text" name="s" className="form-control" placeholder="Search by captions" defaultValue={this.state.searchBy}/>
                                    <button type="submit" className="input-group-text bg-danger border border-danger"><svg className="icon"><use href="#icon_search"></use></svg></button>
                                </div>
                            </form>
                        </div>
                        </div>

                        
                        
                        <div className="caption-tool-menu">
                            <ul className="navbar-nav ms-auto">
                                {
                                    this.props.location.pathname.match(/^.*edit.*$/)?
                                    <>
                                    <div className="nav-item dropdown">
                                        <NavLink to="#" className="nav-link dropdown-toggle text-white p-3 border-end border-secondary nav-images" role="button" data-bs-toggle="dropdown" aria-expanded="true" data-bs-auto-close="false">Images</NavLink>
                                        <div className="dropdown-menu dropdown-menu-lg-end shadow-lg border-0 tiny-preview py-0" data-countimg="1">
                                            <div className="image-tinypreview" data-thisremove="8991919" data-movecount="1">
                                                <img src={this.props.stock_image} alt="tiny-image"/>
                                                <div className="tiny-tools">
                                                    <button type="button" data-tinyremove="8991919">
                                                        <svg className="icon"><use href="#icon_times"></use></svg>
                                                    </button>
                                                    <button type="button" data-rotate="8991919">
                                                        <svg className="icon"><use href="#icon_refresh"></use></svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <label className="add-anotherimg" id="addimage_before">
                                                <img src="images/icon-upload.svg" alt="upload"/>
                                                <input type="file" accept="image/png, image/gif, image/jpeg, image/webp" data-imageload="#addimage_before"/>
                                                <span>Add another image</span>
                                            </label>
                                        </div>
                                    </div>
                                    <li className="nav-item dropdown">
                                        <NavLink to="#" className="nav-link dropdown-toggle text-white p-3 nav-bubbles" role="button" data-bs-toggle="dropdown" aria-expanded="true" data-bs-auto-close="false">Bubbles</NavLink>
                                        <div className="dropdown-menu dropdown-menu-lg-end shadow-lg border-0 element-tools">
                                            <div id="drag-round" token="round" variation="0" className="element element-round ui-draggable" data-images="round-1,round-2,round-3,round-4,round-5,round-6,round-7,round-8" data-fonts="fantasy,monospace,Arial,GoodDog Plain" title="Drag me on the image">
                                                <img className="element-imgfit" src="images/shapes/round-1.png" alt="round"/>
                                            </div>
                                            <div id="drag-medium" token="medium" variation="0" className="element element-medium ui-draggable" data-images="medium-1,medium-2,medium-3,medium-4,medium-5,medium-6" data-fonts="fantasy,monospace,Arial,GoodDog Plain" title="Drag me on the image">
                                                <img className="element-imgfit" src="images/shapes/medium-1.png" alt="medium" />
                                            </div>
                                            <div id="drag-square" token="square" variation="0" className="element element-square ui-draggable" data-images="square-1,square-2,square-3,square-4,square-5,square-6" data-fonts="fantasy,monospace,Arial,GoodDog Plain" title="Drag me on the image">
                                                <img className="element-imgfit" src="images/shapes/square-1.png" alt="square" />
                                            </div>
                                            <div id="drag-angry" token="angry" variation="0" className="element element-angry ui-draggable" data-images="angry-1,angry-2,angry-3,angry-4,angry-5,angry-6" data-fonts="fantasy,monospace,Arial,GoodDog Plain" title="Drag me on the image">
                                                <img className="element-imgfit" src="images/shapes/angry-1.png" alt="angry" />
                                            </div>
                                            <div id="drag-thinking" token="thinking" variation="0" className="element element-thinking ui-draggable" data-images="thinking-1,thinking-2,thinking-3,thinking-4,thinking-5,thinking-6" data-fonts="fantasy,monospace,Arial,GoodDog Plai" title="Drag me on the image">
                                                <img className="element-imgfit" src="images/shapes/thinking-1.png" alt="thinking" />
                                            </div>
                                        </div>
                                    </li>
                                    </>
                                    : ''
                                }
                                {
                                    !this.props.location.pathname.match(/^.*edit.*$/) ?
                                    <>
                                        <li className="nav-item border-start border-secondary">
                                            <NavLink to="/gallery" className="nav-link text-white p-3" title="Gallery">Gallery</NavLink>
                                        </li>
                                        <li className="nav-item bg-danger p-2">
                                            <NavLink to="/create-new-caption" className="nav-link text-white" title="Create New Caption">Create New Caption</NavLink>
                                        </li>
                                        
                                    </>
                                    :''
                                }
                                {
                                    (this.props.location.pathname.match(/^.*edit.*$/))?
                                    (

                                        (this.props.changePhoto)?
                                            <li className="nav-item border-start border-secondary">
                                                <Link to="#" className="nav-link text-white p-3" onClick={this.props.changePhotoFunction} title="Change Photo">Change Photo</Link>
                                            </li>
                                            : 
                                        (this.props.cancel)?
                                            <li className="nav-item border-start border-secondary">
                                                <Link to="/create-new-caption" className="nav-link text-white p-3" title="Cancel">Cancel</Link>
                                            </li>
                                        :''
                                    )
                                    :''
                                }
                                {
                                    (this.props.location.pathname.match(/^.*edit.*$/)) ?
                                        <li className="nav-item border-start border-secondary">
                                            <Link to="#" className="nav-link text-white p-3 btn-danger" id="previewsave">Preview &amp; Save</Link>
                                        </li>
                                    :''
                                }
                            </ul>
                        </div>
                    </div>
                </nav> 
            </header>
            </>
        )
    }
}

export default withRouter(Header);
