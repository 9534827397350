import React, { Component } from 'react';
class Loader extends Component {
    render(){
        return(
            <div className="body-bubbleerror">
                <div className="bubbles-errorbox">
                    <div className="loader" id="loader"></div>
                </div>
            </div>
        )
    }
}
export default Loader;