import React, { Component, Fragment } from 'react';
import { Link, withRouter } from "react-router-dom";
import _ from 'lodash';
import Caption from '../Services/Caption';
import BubbleCard from './components/BubbleCard';
import AdsCard from './components/AdsCard';
import Masonry from 'masonry-layout/dist/masonry.pkgd';
import Pagination from '../Helpers/Pagination';
import Header from "../frontend/components/Header";
import Loader from '../Loader';
import Advertise from '../Services/Advertise'
import Cookies from 'js-cookie';
import imagesLoaded from 'imagesloaded/imagesloaded.pkgd';

const MasonryInt = () => {
    var grid = document.querySelector('#row_masonry');
    var imgLoad = imagesLoaded(grid);

    function onAlways( instance ) {
        var msnry = new Masonry(grid, {
            percentPosition: true,
            itemSelector: '.col',
        });
        msnry.layout()

        document.querySelector('.body-bubbleerror').style.display = 'none';
    }
    imgLoad.on('always', onAlways);  
}

class Gallery extends Component{
    constructor(props) {
        super(props);

        // create a ref to store the DOM element
        this.state = {
            caption_data: '',
            sortBy: 'newest',
            ads_codes: '',
            searchBy: props.location.search ? _.split(props.location.search, '?s=')[1] : '',
            pageCount: 1,
			currentPage: 1,
            isLoading: true,
            item_count: 0
        }

        this.handlePageClick = this.handlePageClick.bind(this);
        this.sortCaption     = this.sortCaption.bind(this);
        this.updateStatus      = this.updateStatus.bind(this);
    }

    async componentDidMount() {
        this.setState({ isLoading: true });
        if(this.props.location.search){
            await Promise.resolve(this.setState(() => ({ searchBy: _.split(this.props.location.search, '?s=')[1] })));
            this.listData()
        }else{
            this.listData()
        }
    }

    async componentDidUpdate(props){
        if(props.location.search != this.props.location.search){
            await Promise.resolve(this.setState(() => ({ searchBy: _.split(this.props.location.search, '?s=')[1], currentPage: 1 })));
            this.listData()
        }
    }

    async handlePageClick(data) {
        this.setState({ isLoading: true });
        const page = data.selected >= 0 ? data.selected + 1 : 0;
		await Promise.resolve(this.setState(() => ({ currentPage: page })));

		this.listData()
	}
    
    async listData() {
        document.querySelector('.body-bubbleerror').style.display = "block";
        Caption.publicList(this.state.currentPage, this.state.sortBy, this.state.searchBy).then(response => {
            this.get_ads_scripts(response);
        }).catch(err => {
            document.querySelector('.body-bubbleerror').style.display = "none";
            console.log(err);
        });
    }

    async sortCaption(sortBy){
        this.setState({ isLoading: true });
        await Promise.resolve(this.setState(() => ({ sortBy: sortBy })));
        this.listData();
    }

    updateStatus(data){
        Caption.like_dislike(data, (response) => {
            if (response.status === 200) {
                this.setState({ 
                    like: 'card-linkshape liked'
                })
                
                let cids_cookies = Cookies.get('cids');
                
                if(data.status == 1){

                    if(cids_cookies){
                        let temp_url = data.temp_url;
                        var cids_arr = JSON.parse(cids_cookies)
                        cids_arr.push(temp_url);
                        Cookies.set('cids', JSON.stringify(cids_arr.filter((v, i, a) => a.indexOf(v) === i)));
                    }else{
                        let temp_url = data.temp_url;
                        let cids_arr = [];
                        cids_arr.push(temp_url);
                        Cookies.set('cids', JSON.stringify(cids_arr));
                    }
                }else{
                    var arr = JSON.parse(cids_cookies)
                    const index = arr.indexOf(data.temp_url);
                    if (index > -1) {
                        arr.splice(index, 1);
                    }
                    Cookies.set('cids', JSON.stringify(arr));
                }
                
                this.listData();
            }
        }, (err) => {
            console.log(err);
        });
    }

    get_ads_scripts(caption_res){
        Advertise.get_ads('scripts', (response) => {
            this.setState({ ads_codes: response.data, caption_data: caption_res.data, isLoading: false , item_count: caption_res.data.data.length});
            if(this.state.isLoading == false && this.state.caption_data.total){
                MasonryInt()
            }else{
                document.querySelector('.body-bubbleerror').style.display = "none";
            }
        }).catch(err => {
            document.querySelector('.body-bubbleerror').style.display = "none";
            console.log(err);
        });
    }

    render(){
        return(
            <>
            <Loader/>
            <Header/>
            <div className="container-fluid">
                { this.state.caption_data.total ? 
                <>
                <div className="row">
                    <div className="col-sm-12 col-12">
                        <div className="d-flex justify-content-end caption-sort">
                            <button onClick={() => this.sortCaption('newest')} className={this.state.sortBy == 'newest' ? 'active me-2 disable' : 'me-2'}>Newest</button> |
                            <button onClick={() => this.sortCaption('view')} className={this.state.sortBy == 'view' ? 'active ms-2' : 'ms-2'}>Popular</button>
                        </div>
                    </div>
                </div>
                <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6 gy-4 mt-0" data-masonry='{"percentPosition": true }' id="row_masonry">
                    {
                        this.state.caption_data.data ? (
                        this.state.caption_data.data.map((item, i)=> 
                            <BubbleCard key={i} index_value={i} item_count={this.state.item_count} caption_data={item} updateStatus={this.updateStatus}  ads_data_600={this.state.ads_codes.ads_600} ads_data_300={this.state.ads_codes.ads_300}/>
                        )
                        ) : ''
                    }
                </div>
                <div className="col-sm-12 mb-5">
                    <div className="justify-content-center d-flex w-100 pt-5">
                        <Pagination data={this.state.caption_data} onPageChange={this.handlePageClick} />
                    </div>
                </div>
                </>
                : 
                <>
                { this.state.isLoading == false ? <div className="row text-center"><h1 className="text-muted mtp-10">No Caption Found!</h1></div>: '' }
                </>
                }
            </div>
            </>
        )
    }
}

export default withRouter(Gallery);