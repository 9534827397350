import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Advertise from '../../Services/Advertise';
import Datatable from '../components/Datatable';
import Loader from '../../Loader';

const setPageTitle = (title) => {
    const prevTitle = document.title;
    document.title = title;
    return () => document.title = prevTitle;
}

class AdvertiseList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dt_data: {
                dt_url: Advertise.list(),
                dt_column: [
                    { "data":"id" , "title": "ID"},
                    { "data":"title", "title":"Title" },
                    { "data":"description", "title":"Description" },
                    { "data":"width", "title":"Width (px)" },
                    { "data":"height", "title":"Height (px)" },
                    { "data":"id", "title":"Action"}
                ],
                dt_column_defs: [
                    {
                        targets: 0,
                        "visible": false,
                    },
                    {
                    targets: 5,
                    createdCell: (td, cellData, rowData, row, col) =>
                        ReactDOM.render(<button type="button" className="btn btn-success btn-sm" onClick={() => this.handleEdit(rowData.id) }><svg className="icon"><use href="#icon_edit"></use></svg></button>, td),
                    }
                ],
                dt_paging: false
            },
            isLoading: true
        }

        this.handleEdit = this.handleEdit.bind(this);
    }

    componentDidMount() {
        this.setState({ isLoading: false})
    }

    handleEdit(id){
        this.props.history.push({ 
            pathname: '/ads-banners/edit/'+id,
        });
    }

    render(){
        setPageTitle('Ads Banners');
        return (
            this.state.isLoading ? <Loader/>
            : 
            <>
            <div className="main-wrapper">
                <div className="container-fluid container-common">
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <h2 className="page-heading">
                            Ads Banners 
                            </h2>
                        </div>
                        
                        <div className="col-sm-12 mt-4">
                            <div className="userlist bg-white p-5">
                                <Datatable dt_data={this.state.dt_data}></Datatable>
                            </div>
                        </div>
                        
                        <div className="col-sm-12 mb-5">
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default AdvertiseList;
