import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const helpers = {
    toastAlert(alertType, alertMessage) {
        let toastAttributes = {
            position: "bottom-right",
        }

        if (alertType == 'success') {
            toast.success(alertMessage, toastAttributes);
        } else {
            toast.error(alertMessage, toastAttributes);
        }
    },

    guidGenerator() {
        var S4 = function() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        };
        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    }


}

export default helpers;