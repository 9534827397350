import axios from "axios";

let API_URL = process.env.REACT_APP_API_URL;

const DashboardService = {
    get: (successCb, failCb) => {
        return axios.get(API_URL+'get-dashboard-data', { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } }).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },
};

export default DashboardService;