import React, { Component } from 'react';
import UserService from '../../Services/Users';
import helpers from '../../Helpers/Helper';
import Loader from '../../Loader';

const setPageTitle = (title) => {
    const prevTitle = document.title;
    document.title = title;
    return () => document.title = prevTitle;
}

class AddUser extends Component {

    constructor(props) {
        super(props);
        this.state={
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            user_type:'',
            isLoading: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
    }

    onChangeValue(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ isLoading: true })
        let datas = {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            password_confirmation: this.state.password_confirmation,
            user_type: this.state.user_type
        }
        UserService.add(datas, (res) => {
            helpers.toastAlert('success', res.data.message);
            this.props.history.goBack();
        }, (err) => {
            this.setState({ isLoading: false })
            let errorObj = err.response.data.errors;
            Object.keys(errorObj).map((item) =>
                errorObj[item].map((message) =>
                    helpers.toastAlert('error', message))
            )
        })
    }

    getUserRole(){
        UserService.getUserRole((res) => {
            if (res.status == 201) {
                this.setState({ userRole: res.data.data, isLoading: false })
            }
        }, (err) => {
            console.log(err)
        })
    }

    async componentDidMount() {
        this.getUserRole()
    }

    render() {
        setPageTitle('AddUser');
        return (
            this.state.isLoading ? <Loader/>
            : 
            <>
                <div className="main-wrapper">
                    <div className="container-fluid container-common">
                        <div className="row justify-content-center">
                            <div className="col-sm-12">
                                <h2 className="page-heading">
                                    AddUser
                            </h2>
                            </div>

                            <div className="col-sm-8 mt-4">
                                <div className="add-listing bg-white p-5">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row g-5 mb-5">
                                            <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" name="name" id="name" onChange={this.onChangeValue} required/>
                                            </div>
                                            <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" name="email" id="email" onChange={this.onChangeValue} required/>
                                            </div>
                                            <label htmlFor="password" className="col-sm-2 col-form-label">Password</label>
                                            <div className="col-sm-10">
                                                <input type="password" className="form-control" name="password" id="password" onChange={this.onChangeValue} required/>
                                            </div>
                                            <label htmlFor="password_confirmation" className="col-sm-2 col-form-label">Retype Password</label>
                                            <div className="col-sm-10">
                                                <input type="password" className="form-control" name="password_confirmation" id="password_confirmation" onChange={this.onChangeValue}required/>
                                            </div>
                                            <label htmlFor="user_type" className="col-sm-2 col-form-label">User Type</label>
                                            <div className="col-sm-10">
                                                <select className="form-select form-select-lg" name="user_type" id="user_type" onChange={this.onChangeValue} required>
                                                    <option value="" className="text-muted">--select--</option>
                                                    {
                                                        (this.state.userRole)?

                                                            this.state.userRole.map(role =>{
                                                                return(
                                                                    <>
                                                                        <option value={role.id}>{role.role_name}</option>
                                                                    </>
                                                                )
                                                            })
                                                        :''
                                                    }
                                                    
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <button type="submit" className="btn btn-danger btn-lg px-3 me-2">
                                                    <svg className="icon me-2"><use href="#icon_submit"></use></svg> Submit
                                            </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="col-sm-12 mb-5">
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AddUser;
