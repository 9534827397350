import axios from "axios";

let API_URL = process.env.REACT_APP_API_URL;

const Setting = {
    get: (successCb, failCb) => {
        return axios.get(API_URL+'get-setting',{ headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } }).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    getSettings: (successCb, failCb) => {
        return axios.get(API_URL+'get-setting-data').then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },
    
    add: (data, successCb, failCb) => {
        axios.post(API_URL+'add-setting', data, { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } }).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    changePassword: (data, successCb, failCb) => {
        axios.post(API_URL+'change-password', data, { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } }).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },
};

export default Setting;