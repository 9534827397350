import React, { Component } from 'react';
import { Link, withRouter} from "react-router-dom";
import GlobalContext from '../Context/GlobalContext';
import Helper from '../Helpers/Helper'
import Loader from '../Loader';
import Stock from '../Services/Stock';
import $ from 'jquery';
import {Helmet} from "react-helmet";

class CreateNewCaption extends Component{
    static contextType = GlobalContext;
    constructor(props, context) {
        super(props);

        this.state = {
            site_logo: context.settingDetails.site_logo,
            site_name: context.settingDetails.site_name,
            isLoading: true
        }
        this.uploadFromStock = this.uploadFromStock.bind(this);
        this.uploadFromDevice = this.uploadFromDevice.bind(this);
    }

    uploadFromDevice(e){
        $('body').addClass('body-bubbleerror');
        const formData = new FormData();
        var file = e.target.files[0];
        var sizeMb = file.size/(1024*1024)
        if(parseFloat(sizeMb) > 2){
            Helper.toastAlert('error', 'Upload image upto 2 MB.')
            $('body').removeClass('body-bubbleerror');
            return
        }
        
        formData.append('attachments[]', file);
        Stock.add(formData, (response) => {
            if (response.status == 201) {
                if(response.data.uploaded > 0){
                    localStorage.setItem("device_image", response.data.data.uploaded[0]);
                    this.uploadFromStock()
                }
                if(response.data.size_error > 0){
                    Helper.toastAlert('error', 'Minimum image size would be 640x480');
                    $('body').removeClass('body-bubbleerror');
                }
                if(response.data.failed > 0){
                    Helper.toastAlert('error', 'Unable to upload image. Please upload image in '+ response.data.file_type + ' format.');
                    $('body').removeClass('body-bubbleerror');
                }
            } else {
                Helper.toastAlert('error', response.data.message);
                $('body').removeClass('body-bubbleerror');
            }
        }, (err) => {
            let errorObj = err.response.data.errors;
            Helper.toastAlert('error', errorObj)
            $('body').removeClass('body-bubbleerror');
        })
    }

    uploadFromStock(){
        $('body').addClass('body-bubbleerror');
        this.props.history.push({
            pathname: "/edit/" + Helper.guidGenerator(),
        });
    }

    removeFromStock(filename){
        localStorage.removeItem("device_image");
        Stock.delete({ filename: filename}, (response) => {
            if (response.status == 200) {
                
            }
        }, (err) => {
            console.log(err);
        })
    }
    componentDidMount(){
        this.setState({isLoading: false});
        var deviceImage = localStorage.getItem("device_image");
        if (deviceImage){
            this.removeFromStock(deviceImage)
        }
    }

    render(){
        return(
            <>
            <Helmet>
                <title>Create A New Caption</title>
                <meta name="description" content={this.state.site_name} />
            </Helmet>
            {this.state.isLoading ? <Loader text='Loading...'/>
            : 
            <>
                <div className="bubbles-errorbox">
                    <h2>Loading...</h2>
                </div>
                <section className="mt-4">
                    <div className="container">
                        <div className="row row justify-content-center">
                            <div className="col-sm-12 col-md-10 col-lg-6 text-center">
                                <form className="row g-3 g-sm-4">
                                    <div className="bg-light shadow-sm rounded border-0 p-4 upload-box">
                                        <Link to="/" className="logo-caption navbar-brand text-white fs-3 fw-bolder p-0"><img src={this.state.site_logo} alt={this.state.site_name} /></Link>
                                        <p className="fs-4">Caption your story</p>
                                        <p className="lh-base">Time to hit it with our simple and free online speech bubble platform. Add speech bubbles to photos to create your own story strips and fulfil your fantasy - ready within seconds! amazing results guaranteed, completely free, no sign-up required.</p>
                                        <p className="lh-base">Start now by choosing one of the two options below. You can create a speech bubble image with your own photo or use one of our random stock photos.</p>
                                        <div className="mb-3">
                                            <label className="drop-input btn-outline-danger">
                                                <div><svg className="icon fs-2 me-2"><use href="#icon_upload"></use></svg></div>
                                                    <input type="file" id="file" onChange={this.uploadFromDevice}/>
                                                <div className="fs-5">Upload Photo From Your Device</div>
                                            </label>
                                        </div>
                                        <div>
                                            <button type="button" className="drop-input btn-outline-danger" onClick={this.uploadFromStock}>
                                                <div><svg className="icon fs-2 me-2"><use href="#icon_upload"></use></svg></div>
                                                    <span className="fs-5">Use Random Stock Photo</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </>
            }
            </>
        )
    }
}

export default withRouter(CreateNewCaption);