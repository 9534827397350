import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Cookies from 'js-cookie'
import _ from 'lodash';

class GalleryCard extends Component{
    constructor(props){
        super(props);

        this.state = {
            like : 'card-linkshape',
            likeStatus:1
        }

        this.handleCaptionStatus = this.handleCaptionStatus.bind(this); 
        this.showCaption = this.showCaption.bind(this);
    } 

    handleCaptionStatus(status) {

        this.props.updateStatus({
            temp_url: this.props.caption_data.temp_url,
            status: status
        });

        if (status == 1){
            this.setState({
                like: 'card-linkshape liked',
                likeStatus:2
            })
        }else{
            this.setState({
                like: 'card-linkshape',
                likeStatus: 1
            })
        }

    } 

    showCaption(e){
        this.props.history.push({ 
            pathname: '/show/'+this.props.caption_data.temp_url,
        });
    }

    componentDidMount(){
        let cids_cookies = Cookies.get('cids');
        if (cids_cookies && cids_cookies.includes(this.props.caption_data.temp_url)){
            this.setState({
                like: 'card-linkshape liked',
                likeStatus:2
            })
        }
    }
    
    render(){
        return(
            <>
            { this.props.index_value == 4 ?
            <div className="col">
                <div className="card card-box shadow-sm bg-body rounded overflow-hidden border-0 position-relative cursor-pointer">
                    <div className="ads-code s300x300">
                        <div className="ads-title text-center">
                            Advertisement
                        </div>
                        {
                            this.props.ads_data_300 ?
                            <div className="ads-content" dangerouslySetInnerHTML={{
                                __html: this.props.ads_data_300.script
                            }}></div>
                            
                            :
                            ''
                        }
                    </div>
                </div>
            </div>
            : '' }

            <div className="col">
                <div className="card card-box shadow-sm bg-body rounded overflow-hidden border-0 position-relative cursor-pointer">
                    <button type="button" className={this.state.like} onClick={() => this.handleCaptionStatus(this.state.likeStatus)}>
                        <svg className="icon"><use xlinkHref="#icon_heart"></use></svg>
                    </button>
                    <div className="card-imgbox">
                        <img src={this.props.caption_data.image_path} alt={this.props.caption_data.caption} onClick={() => this.showCaption()} onError={(e) => {e.target.onerror = null; e.target.src = this.props.caption_data.dummy_image}}/>
                    </div>
                    {this.props.caption_data.caption != null ?
                        <div className="card-body" onClick={() => this.showCaption()}>
                            <ul className="card-bubbleaction">
                                <li>
                                    <svg className="icon"><use xlinkHref="#icon_eye"></use></svg>
                                    <span>{this.props.caption_data.views}</span>
                                </li>
                                <li>
                                    <svg className="icon"><use xlinkHref="#icon_heartline"></use></svg>
                                    <span>{this.props.caption_data.likes}</span>
                                </li>
                                <li>
                                    <svg className="icon"><use xlinkHref="#icon_share"></use></svg>
                                    <span>{this.props.caption_data.share}</span>
                                </li>
                                <li>
                                    <svg className="icon"><use xlinkHref="#icon_comments"></use></svg>
                                    <span>{this.props.caption_data.comment}</span>
                                </li>
                            </ul>
                            <p className="card-text">{this.props.caption_data.caption}</p>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            
            { this.props.index_value == 9 ?
            <div className="col">
                <div className="card card-box shadow-sm bg-body rounded overflow-hidden border-0 position-relative cursor-pointer">
                    <div className="ads-code s300x600">
                        <div className="ads-title text-center">
                            Advertisement
                        </div>
                        {
                            this.props.ads_data_600 ?
                            <div className="ads-content" dangerouslySetInnerHTML={{
                                __html: this.props.ads_data_600.script
                            }}></div>
                            
                            :
                            ''
                        }
                    </div>
                </div>
            </div>
            : '' }
            </>
        )
    }
}
export default withRouter(GalleryCard);