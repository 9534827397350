import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Page from '../../Services/Page';
import GlobalContext from '../../Context/GlobalContext';

class Footer extends Component {
    static contextType = GlobalContext;

    constructor(props, context) {
        super(props);

        this.state = {
            copyright: context.settingDetails.copyright,
            pageData:''
        }
        this.getPages = this.getPages.bind(this);
    }
    
    getPages(){
        Page.get((res) => {
            this.setState({
                pageData: res.data.data
            })
        }, (err) => {
            console.log(err)
        })
    }

    componentDidMount(){
        this.getPages();
    }

    render(){
        return(
            <footer>
                <div className="container-fluid">
                    <div className="row py-2">                            
                        <div className="col-lg-6 order-lg-2 text-center text-lg-end py-1">
                            <ul className="list-inline mb-0">
                                {
                                    (this.state.pageData)?
                                    this.state.pageData.map((page,i) => {
                                        return(
                                            <span key={i}>
                                                <li key={page.id} className="list-inline-item ms-2">
                                                    <Link to={"/page/"+page.slug} className="" title="Terms of Use">
                                                        {page.title}
                                                    </Link>
                                                </li>
                                                {i < 6 ? <li className="list-inline-item text-muted px-1">|</li>:''}
                                                
                                            </span>
                                        )
                                    })
                                    :''
                                }
                            </ul>
                        </div>
                        <div className="col-lg-6 text-center text-lg-start py-1">
                            {this.state.copyright}
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;