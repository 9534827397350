import React, { Component } from 'react';
import { Link } from "react-router-dom";
import DashboardService from '../../Services/DashboardService';
import Loader from '../../Loader';

const setPageTitle = (title) => {
    const prevTitle = document.title;
    document.title = title;
    return () => document.title = prevTitle;
}

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dashboard: '',
            isLoading: true
        }
    }

    componentDidMount() {
        this.dashboardData()
    }
    
    async dashboardData() {
        DashboardService.get((response) => {
            this.setState({ dashboard: response.data, isLoading: false });
        }, (err) => {
            console.log(err);
        })
    }
    render(){
        setPageTitle('Dashboard');
        return (
            this.state.isLoading ? <Loader/>
            : 
            <>
            <div className="main-wrapper">
                <div className="container-fluid container-common">
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <h2 className="page-heading">
                                Dashboard <span>v.01</span>
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                            <Link to="/caption-images" className="text-decoration-none">
                                <div className="dashboard-box bg-danger text-center p-4 text-white shadow p-3 mb-5 rounded">
                                    <div><svg className="icon fs-1 mb-3"><use href="#icon_captionimage"></use></svg></div>
                                    <h2 className="fs-4">Caption Images</h2>
                                    <div className="value-box fs-2">{this.state.dashboard.caption_count ? this.state.dashboard.caption_count : 0}</div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                            <Link to="/comments" className="text-decoration-none">
                                <div className="dashboard-box bg-danger text-center p-4 text-white shadow p-3 mb-5 rounded">
                                    <div><svg className="icon fs-1 mb-3"><use href="#icon_comments"></use></svg></div>
                                    <h2 className="fs-4">Comments</h2>
                                    <div className="value-box fs-2">{this.state.dashboard.comment_count ? this.state.dashboard.comment_count : 0}</div>
                                </div>
                            </Link>
                        </div>
                        
                        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                            <Link to="/reports" className="text-decoration-none">
                                <div className="dashboard-box bg-danger text-center p-4 text-white shadow p-3 mb-5 rounded">
                                    <div><svg className="icon fs-1 mb-3"><use href="#icon_report"></use></svg></div>
                                    <h2 className="fs-4">Reports</h2>
                                    <div className="value-box fs-2">{this.state.dashboard.report_count ? this.state.dashboard.report_count : 0}</div>
                                </div>
                            </Link>
                        </div>
                        
                        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                            <Link to="/stock-images" className="text-decoration-none">
                                <div className="dashboard-box bg-danger text-center p-4 text-white shadow p-3 mb-5 rounded">
                                    <div><svg className="icon fs-1 mb-3"><use href="#icon_captionimage"></use></svg></div>
                                    <h2 className="fs-4">Stock Images</h2>
                                    <div className="value-box fs-2">{this.state.dashboard.stock_count ? this.state.dashboard.stock_count : 0}</div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                            <Link to="/stock-images" className="text-decoration-none">
                                <div className="dashboard-box bg-danger text-center p-4 text-white shadow p-3 mb-5 rounded">
                                    <div><svg className="icon fs-1 mb-3"><use xlinkHref="#icon_eye"></use></svg></div>
                                    <h2 className="fs-4">Total Views</h2>
                                    <div className="value-box fs-2">{this.state.dashboard.caption_total_view ? this.state.dashboard.caption_total_view : 0}</div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                            <Link to="/stock-images" className="text-decoration-none">
                                <div className="dashboard-box bg-danger text-center p-4 text-white shadow p-3 mb-5 rounded">
                                    <div><svg className="icon fs-1 mb-3"><use href="#icon_share"></use></svg></div>
                                    <h2 className="fs-4">Total Share</h2>
                                    <div className="value-box fs-2">{this.state.dashboard.caption_total_share ? this.state.dashboard.caption_total_share : 0}</div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                            <Link to="/stock-images" className="text-decoration-none">
                                <div className="dashboard-box bg-danger text-center p-4 text-white shadow p-3 mb-5 rounded">
                                    <div><svg className="icon fs-1 mb-3"><use href="#icon_heartline"></use></svg></div>
                                    <h2 className="fs-4">Total Likes</h2>
                                    <div className="value-box fs-2">{this.state.dashboard.caption_total_like ? this.state.dashboard.caption_total_like : 0}</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default Dashboard;
