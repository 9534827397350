import React, { Component } from 'react';
import Advertise from '../../Services/Advertise';
import helpers from '../../Helpers/Helper';
import Loader from '../../Loader';

class AdvertiseEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            advertise: '',
            isLoading: true
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ isLoading: true });

        const formData = new FormData(e.target);
        formData.append("id", this.state.advertise.id);
        
        Advertise.update(formData, (response) => {
            if (response.data.success) {
                helpers.toastAlert('success', response.data.message);
                this.props.history.goBack();
            } else {
                helpers.toastAlert('error', response.data.message)
            }
            this.setState({ isLoading: false });
        }, (err) => {
            this.setState({ isLoading: false });
            let errorObj = err.response.data.errors;
            Object.keys(errorObj).map((item) => helpers.toastAlert('error', errorObj[item])
            )
        })
    }
   
    async componentDidMount() {
      this.getDetails();
    }

    getDetails(){
        const { match: { params } } = this.props;

        Advertise.detail( { id: params.id } , (res) => {
            if (res.data) {
                this.setState({
                    advertise: res.data.data,
                    isLoading: false
                })
            }
        }, (err) => {
            console.log(err)
        });
    }

    render() {
        return (
            this.state.isLoading ? <Loader/>
            : 
            <>
            <div className="main-wrapper">
                <div className="container-fluid container-common">
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <h2 className="page-heading">
                            Edit Ads Banner
                            </h2>
                        </div>
                        <div className="mt-2">
                            <div className="d-flex justify-content-end"><button onClick={this.props.history.goBack} className="btn btn-dark px-3 btn-sm"><svg className="icon me-2"><use href="#icon_arrowleft"></use></svg> Back</button></div>
                        </div>
                        
                        <div className="col-sm-8 mt-4">
                            <div className="add-listing bg-white p-5">
                                <form onSubmit={this.handleSubmit} method="post">
                                    <div className="row g-5 mb-5">
                                        <div className="col-12">
                                            <input type="text" id="title" name="title" className="form-control" defaultValue={this.state.advertise.title} placeholder="Title" aria-label="Title" readOnly/>
                                        </div>
                                        <div className="col-12">
                                            <textarea id="description" name="description" className="form-control" defaultValue={this.state.advertise.description} placeholder="Description" aria-label="Description" readOnly></textarea>
                                        </div>
                                        <div className="col-12">
                                            <textarea id="script" name="script" className="form-control" defaultValue={this.state.advertise.script} placeholder="Script" aria-label="Script" rows="10"></textarea>
                                        </div>
                                        <div className="col-6">
                                            <input type="number" id="width" name="width" className="form-control" defaultValue={this.state.advertise.width} placeholder="Width" aria-label="Width" readOnly/>
                                        </div>
                                        <div className="col-6">
                                            <input type="number" name="height" className="form-control" defaultValue={this.state.advertise.height} placeholder="Height" aria-label="Height" readOnly/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <button type="submit" className="btn btn-danger btn-lg px-3 me-2">
                                                <svg className="icon me-2"><use href="#icon_submit"></use></svg> Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                        <div className="col-sm-12 mb-5">
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default AdvertiseEdit;