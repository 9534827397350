import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, BrowserRouter, Route } from "react-router-dom";

//Global Components
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './index.css';
import Icons from "./Icons"; // Whole icons load from Icons.js file.
import { GlobalProvider } from './Context/GlobalContext';

//Frontend Components
import Header from "./frontend/components/Header";
import Footer from "./frontend/components/Footer";

import Login from "./backend/Login";
import Home from "./frontend/Home";
import CreateNewCaption from "./frontend/CreateNewCaption";
import PreviewShare from "./frontend/PreviewShare";
import Page from "./frontend/Page";
import RandomPhotos from "./frontend/RandomPhotos";

//Backend Components
import AdminRoutes from "./backend/components/AdminRoutes";
import NotFound from './Error/NotFound';

ReactDOM.render(
    
  <React.StrictMode>
    {/* <BrowserRouter basename={"/work/caption"}> */}
    <GlobalProvider>
        <BrowserRouter>
            <Switch>
                <Route exact path="/">
                    <Home />
                    <Footer />
                </Route>

                <Route exact path="/gallery">
                    <Home />
                    <Footer />
                </Route>

                <Route exact path="/search">
                    <Home />
                    <Footer />
                </Route>

                <Route exact path="/show/:slug" render={(props) => <>
                        <Header />
                        <PreviewShare {...props} /> 
                        <Footer />
                </> } />  

                <Route exact path="/create-new-caption">
                    <Header />
                    <CreateNewCaption />
                    <Footer />
                </Route>

                <Route exact path="/login">
                    <Login />
                </Route>

                <Route path='/page/:slug' render={(props) => <>
                    <Header />
                    <Page {...props} /> 
                    <Footer />
                </> } />
                  
                <Route path="/edit/:uuid" render={(props) => <>
                    <RandomPhotos {...props} />
                    <Footer />
                </>} />
                        
                <AdminRoutes path="/admin"/>

                <Route>
                    <Header />
                    <NotFound />
                    <Footer />
                </Route>
            </Switch>
        </BrowserRouter>
    </GlobalProvider>

    <Icons />
    
  </React.StrictMode>,
  document.getElementById('root')
);
