import axios from "axios";

let API_URL = process.env.REACT_APP_API_URL;

const Comment = {
    
    list: () => {
        return API_URL+'get-reports';
    },

    delete: (data, successCb, failCb) => {
        axios.post(API_URL + 'delete-report', data, { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } }).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },
   
};

export default Comment;